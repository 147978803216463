import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Slider from "../../components/Slider/Slider";
import Footer from "../../components/Footer/Footer";
import FAQ from "../../components/FAQ/FAQ";
import Announcements from "../../components/Announcements/Announcements";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import NewsCarousel from "../../components/NewsCarousel/NewsCarousel";
import Partners from "../../components/Partners/Partners";
import Projects from "../../components/Projects/Projects";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import s from "./Home.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprisers,
  getBoardMembers,
  getConsultants,
  getContacts,
  getCouncilMembers,
  getInsuranceCompanies,
  getNews,
  getReports,
  getSlider,
  getTenders,
  getVacancies,
} from "../../redux/ApplicationSlice";
import Map from "../../components/Map/Map";
import MainPageHeader from "../../components/MainPageHeader/MainPageHeader";
import CreditCalculator from "../../components/CreditCalculator/CreditCalculator";

const Home = () => {
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.application.lang);

  useEffect(() => {
    dispatch(getNews());
    dispatch(getTenders());
    dispatch(getVacancies());
    dispatch(getBoardMembers());
    dispatch(getCouncilMembers());
    dispatch(getReports());
    dispatch(getContacts());
    dispatch(getApprisers());
    dispatch(getConsultants());
    dispatch(getInsuranceCompanies());
  }, [dispatch]);

  return (
    <div className={s.home}>
      <MainPageHeader />

      {/* <Slider /> */}
      <NewsCarousel />
      <FAQ />
      {/* <Partners /> */}
      {/* <Announcements /> */}
      <FeedbackForm />
      <CreditCalculator />
      <Map />
      <Footer />
    </div>
  );
};

export default Home;
