import React, { useState } from "react";
import s from "./MobileHeader.module.scss";
import { Dropdown, Menu, Modal } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  HomeOutlined,
  ApartmentOutlined,
  TeamOutlined,
  BankOutlined,
  AuditOutlined,
  LinkOutlined,
  BookOutlined,
  EditOutlined,
  IdcardOutlined,
  NotificationOutlined,
  UserOutlined,
  LogoutOutlined,
  GlobalOutlined,
  CaretDownFilled,
} from "@ant-design/icons";
import ApplicationDrawer from "../ApplicationDrawer/ApplicationDrawer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import {
  getApprisers,
  getBoardMembers,
  getConsultants,
  getContacts,
  getCouncilMembers,
  getInsuranceCompanies,
  getNews,
  getNewsDetails,
  getReports,
  getTenderDetails,
  getTenders,
  logout,
  setLang,
} from "../../redux/ApplicationSlice";

const MobileHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.application.user);
  const location = useLocation();
  const lang = useSelector((state) => state.application.lang);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(getNews());
    dispatch(getTenders());
    dispatch(getBoardMembers());
    dispatch(getCouncilMembers());
    dispatch(getContacts());
    dispatch(getConsultants());
    dispatch(getApprisers());
    dispatch(getReports());
    dispatch(getInsuranceCompanies());

    params.id && dispatch(getTenderDetails(Number(params.id)));
    params.id && dispatch(getNewsDetails(Number(params.id)));
  };

  const options4 = (
    <Menu>
      <Menu.Item key='1'>
        <div
          onClick={() => {
            dispatch(setLang("ru"));
            changeLang("ru");
          }}
        >
          {/* <IdcardOutlined className={"icon"} /> */}
          RU
        </div>
      </Menu.Item>
      <Menu.Item key='2'>
        <div
          onClick={() => {
            dispatch(setLang("en"));
            changeLang("en");
          }}
        >
          {/* <ContactsOutlined className={"icon"} />  */}
          EN
        </div>
      </Menu.Item>
    </Menu>
  );

  const options5 = (
    <Menu>
      {localStorage.getItem("access_token") && (
        <Menu.Item key='2'>
          <div
            onClick={() =>
              user?.email
                ? navigate(user.is_admin ? "/adminAccount" : "/myAccount")
                : navigate("/login")
            }
          >
            <UserOutlined className={"icon"} />
            Личный кабинет
          </div>
        </Menu.Item>
      )}
      {localStorage.getItem("access_token") ? (
        <Menu.Item key='3'>
          <div
            onClick={() => {
              dispatch(logout());
              navigate("/");
            }}
          >
            <LogoutOutlined className={"icon"} /> {t("logout")}
          </div>
        </Menu.Item>
      ) : (
        <Menu.Item key='1'>
          <Link to='/login'>
            <UserOutlined className={"icon"} />
            {t("login")}
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className={s.mobileHeader}>
      <Link to='/' className={s.logo}>
        <div className={s.logo_img}>
          <img
            src={lang === "ru" ? "/dark_logo.png" : "/akdf_logo(eng).png"}
            className={s.header_logo}
          />
        </div>
      </Link>
      <div className={s.mobile_menu} onClick={() => setIsModalOpen(true)}>
        <div className=''></div>
        <div></div>
      </div>

      <Modal
        width={400}
        height={280}
        onCancel={() => setIsModalOpen(false)}
        style={{ borderRadius: "10px", width: "90%", margin: "0 auto" }}
        cancelButtonProps={{ style: { display: "none", color: "whitesmoke" } }}
        okButtonProps={{ style: { display: "none" } }}
        className={s.mobile_menu_modal}
        open={isModalOpen}
      >
        <div className={s.modal_header}>
          <Dropdown overlay={options4}>
            <ol className={location.pathname === "/news" ? "active" : ""}>
              <div>
                <GlobalOutlined className={s.icon} />
                {lang === "en" ? "EN" : "RU"}
              </div>
            </ol>
          </Dropdown>

          <Dropdown overlay={options5}>
            <ol>
              {user?.email ? (
                <div className={"img_wrapper"}>
                  <img src='/assets/noImage.png' className={"img"} />{" "}
                  <CaretDownFilled className={s.icon} />
                </div>
              ) : (
                t("login")
              )}
            </ol>
          </Dropdown>
        </div>
        <form className='mobile_header' onSubmit={(e) => e.preventDefault()}>
          <Link
            to='/'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <HomeOutlined className={s.icon} />
            </div>
            {t("home")}
          </Link>
          <Link
            to='/about'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <ApartmentOutlined className={s.icon} />
            </div>
            {t("aboutUs")}
          </Link>
          <Link
            to='/team'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <TeamOutlined className={s.icon} />
            </div>
            {t("management")}
          </Link>
          <Link
            to='/reports'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <BookOutlined className={s.icon} />
            </div>
            {t("policies")}
          </Link>
          <div
            className={s.mobile_menu_item}
            onClick={() => {
              showDrawer(true);
              setIsModalOpen(false);
            }}
          >
            <div className='slash'>
              <EditOutlined className={s.icon} />
            </div>
            {t("application")}
          </div>
          <Link
            to='/requirements'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <BankOutlined className={s.icon} />
            </div>
            {t("termsOfInvestmentLending")}
          </Link>
          <Link
            to='/apprisers'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <IdcardOutlined className={s.icon} />
            </div>
            {t("accreditedAppraisers")}
          </Link>
          <Link
            to='/consultants'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <IdcardOutlined className={s.icon} />
            </div>
            {t("accreditedConsultants")}
          </Link>
          <Link
            to='/insuranceCompanies'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <IdcardOutlined className={s.icon} />
            </div>
            {t("accreditedInsuranceCompanies")}
          </Link>

          <Link
            to='/partnersPage'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <TeamOutlined className={s.icon} />
            </div>
            {t("partners")}
          </Link>
          <Link
            to='/news'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <NotificationOutlined className={s.icon} />
            </div>
            {t("news")}
          </Link>
          <Link
            to='/tenders'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <AuditOutlined className={s.icon} />
            </div>
            {t("tenders")}
          </Link>
          <Link
            to='/vacancies'
            className={s.mobile_menu_item}
            onClick={() => setIsModalOpen(false)}
          >
            <div className='slash'>
              <LinkOutlined className={s.icon} />
            </div>
            {t("vacancies")}
          </Link>
        </form>
      </Modal>
      <ApplicationDrawer open={open} onClose={onClose} />
    </div>
  );
};

export default MobileHeader;
