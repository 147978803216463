import React, { useState } from "react";
import s from "./FeedbackForm.module.scss";
import "../../styles/style.css";
import { Form, Input, Row, Col, FormItem, Select, message } from "antd";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import { useDispatch } from "react-redux";
import { sendFeedback } from "../../redux/ApplicationSlice";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const { Option } = Select;

const FeedbackForm = () => {
  const [form] = Form.useForm();
  const { height, width } = useWindowDimensions();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [clientMessage, setClientMessage] = useState();
  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Вы отправили сообщение!",
    });
  };

  const getFeedback = async () => {
    try {
      form.validateFields();
      let re = /\S+@\S+\.\S+/;
      if (
        name &&
        phone &&
        email &&
        subject &&
        clientMessage &&
        re.test(email)
      ) {
        const res = await dispatch(
          sendFeedback({
            full_name: name,
            phone,
            email,
            subject,
            message: clientMessage,
          })
        );

        if (res.meta.requestStatus === "fulfilled") {
          success();
          form.resetFields();
          setName("");
          setPhone("");
          setEmail("");
          setClientMessage("");
          setSubject("");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={s.feedback}>
      {contextHolder}
      <div className={s.wrapper}>
        <h2 className={s.title}>{t("contactUs")}</h2>

        <Form className={s.form} layout='vertical' form={form}>
          <Row gutter={24}>
            <Col span={width > 700 ? 12 : 24}>
              <Form.Item
                name='username'
                label={t("fullName")}
                rules={[
                  {
                    required: true,
                    message: t("provideFullName"),
                  },
                ]}
              >
                <Input
                  className={s.input}
                  placeholder={t("provideFullName")}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={width > 700 ? 12 : 24}>
              <Form.Item
                label={t("phone")}
                name='phone'
                rules={[
                  {
                    required: true,
                    message: t("providePhoneNumber"),
                  },
                ]}
              >
                <Input
                  className={s.input}
                  placeholder={t("providePhoneNumber")}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={width > 700 ? 12 : 24}>
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  { required: true, message: t("enterEmail") },
                  { type: "email", message: t("enterValidEmail") },
                ]}
              >
                <Input
                  className={s.input}
                  placeholder={t("enterEmail")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={width > 700 ? 12 : 24}>
              <Form.Item
                label={t("subject")}
                name='subject'
                rules={[
                  {
                    required: true,
                    message: t("specifyTheSubject"),
                  },
                ]}
              >
                <Input
                  className={s.input}
                  placeholder={t("specifyTheSubject")}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t("message")}
                name='message'
                rules={[
                  {
                    required: true,
                    message: t("writeMessage"),
                  },
                ]}
              >
                <TextArea
                  className={s.input}
                  style={{ minHeight: "100px" }}
                  placeholder={t("writeMessage")}
                  onChange={(e) => setClientMessage(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <button className={s.btn} onClick={(e) => getFeedback()}>
            {t("send")}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default FeedbackForm;
