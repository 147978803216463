import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import s from "./UpdateUser.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, message, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  getProfileInfo,
  logout,
  updateUser,
} from "../../redux/ApplicationSlice";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import UserInfo from "../../components/UserInfo/UserInfo";

const UpdateUser = () => {
  const user = useSelector((state) => state.application.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.application.access);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [changedFields, setChangedFields] = useState({});
  const { width } = useWindowDimensions();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Вы обновили информацию о пользователе!",
    });
  };

  const columns = [
    {
      title: "Дата",
      dataIndex: `date`,
      width: 150,
    },
    {
      title: "Компания",
      dataIndex: "company",
      width: 300,
    },
    {
      title: "Сумма",
      dataIndex: `requestedAmount`,
      width: 125,
      align: "center",
    },
    {
      title: "Цель кредита",
      dataIndex: `intendedUse`,
      width: 300,
      align: "center",
    },

    {
      title: "Cтатус",
      dataIndex: "published",
      width: 100,
    },
    {
      title: "Сообщения",
      dataIndex: `chat`,
      width: 100,
    },
  ];

  const handleValuesChange = (changedValues) => {
    setChangedFields((prevChangedFields) => ({
      ...prevChangedFields,
      ...changedValues,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getProfileInfo());
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    form.setFieldsValue({
      full_name: user?.full_name,
      job_title: user?.job_title,
      company: user?.company,
      phone_number: user?.phone_number,
      address: user?.address,
    });
  }, [user, form]);

  const sendForm = async () => {
    const data = new FormData();
    Object.entries(changedFields).forEach(([key, value]) => {
      data.append(key, value.value || value);
    });

    const res = await dispatch(updateUser(data));
    if (res.type === "user/updateUser/fulfilled") {
      success();
      dispatch(getProfileInfo());
    }
  };

  return (
    <div>
      {contextHolder}
      {width > 1020 ? <Header /> : <MobileHeader />}
      <div className={s.myAccount}>
        <div className={s.wrapper}>
          {width > 1020 ? (
            <div className={s.personalInfo}>
              <div className={s.personalInfo}>
                <div className={s.image}>
                  <div className={s.img_wrapper}>
                    <img src='/assets/noImage.png' alt='' className={s.img} />
                  </div>
                </div>
                <div className={s.content_item}>
                  <div className={s.content_item_title}>Email:</div>
                  <div className={s.content_item_value}>{user?.email}</div>
                </div>
                {user && user?.full_name && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>ФИО:</div>
                    <div className={s.content_item_value}>
                      {user?.full_name}
                    </div>
                  </div>
                )}
                {user?.job_title && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>Должность:</div>
                    <div className={s.content_item_value}>
                      {user?.job_title}
                    </div>
                  </div>
                )}
                {user?.company && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>Компания:</div>
                    <div className={s.content_item_value}>{user?.company}</div>
                  </div>
                )}
                {user?.address && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>Адрес:</div>
                    <div className={s.content_item_value}>{user?.address}</div>
                  </div>
                )}
                {user?.phone_number && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>Телефон:</div>
                    <div className={s.content_item_value}>
                      {user?.phone_number}
                    </div>
                  </div>
                )}
              </div>
              <Link to='/myAccount' className={s.btn}>
                Мои заявления
              </Link>
            </div>
          ) : (
            <div className={s.personalInfo}>
              <UserInfo />
            </div>
          )}
          <div className={s.content}>
            <h4>Обновить профиль</h4>
            <Form
              form={form}
              layout='vertical'
              onFinish={sendForm}
              onValuesChange={handleValuesChange}
            >
              <Row gutter={24}>
                <Col span={width > 900 ? 12 : 24}>
                  <Form.Item label='ФИО' name='full_name'>
                    <input className={s.input} />
                  </Form.Item>
                </Col>
                <Col span={width > 900 ? 12 : 24}>
                  <Form.Item label='Должность' name='job_title'>
                    <input className={s.input} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={width > 900 ? 12 : 24}>
                  <Form.Item label='Компания' name='company'>
                    <input className={s.input} />
                  </Form.Item>
                </Col>
                <Col span={width > 900 ? 12 : 24}>
                  <Form.Item label='Адрес' name='address'>
                    <input className={s.input} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={width > 900 ? 12 : 24}>
                  <Form.Item label='Телефон' name='phone_number'>
                    <input className={s.input} />
                  </Form.Item>
                </Col>
              </Row>
              <div className={s.btn_wrapper}>
                <button htmlType='submit' className={s.btn}>
                  <EditOutlined className={s.edit_icon} /> Обновить
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
