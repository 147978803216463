import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { useTranslation } from "react-i18next";
import s from "./UserInfo.module.scss";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const UserInfo = () => {
  const [openedAccordionKey, setOpenedAccordionKey] = useState(0);
  const { t } = useTranslation();
  const user = useSelector((state) => state.application.user);
  return (
    <div className={s.wrapper}>
      <Accordion
        allowZeroExpanded
        className={s.accordion}
        allowMultipleExpanded={false}
      >
        <AccordionItem key={1} className={s.item}>
          <AccordionItemHeading
            className={s.heading}
            onClick={(e) => setOpenedAccordionKey(1)}
          >
            <AccordionItemButton className={s.acc_btn}>
              <div className={s.accordion_header}>
                <div className={s.img_wrapper}>
                  <img src='/assets/noImage.png' alt='' className={s.img} />
                </div>
                <div className={s.email}>{user?.email}</div>
              </div>
              <div>
                {openedAccordionKey === 1 ? (
                  <CaretUpOutlined className={s.icon} />
                ) : (
                  <CaretDownOutlined className={s.icon} />
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.panel}>
            <div className={s.personalInfo}>
              {user && user?.full_name && (
                <div className={s.content_item}>
                  <div className={s.content_item_title}>ФИО:</div>
                  <div className={s.content_item_value}>{user?.full_name}</div>
                </div>
              )}
              {user?.job_title && (
                <div className={s.content_item}>
                  <div className={s.content_item_title}>Должность:</div>
                  <div className={s.content_item_value}>{user?.job_title}</div>
                </div>
              )}
              {user?.company && (
                <div className={s.content_item}>
                  <div className={s.content_item_title}>Компания:</div>
                  <div className={s.content_item_value}>{user?.company}</div>
                </div>
              )}
              {user?.address && (
                <div className={s.content_item}>
                  <div className={s.content_item_title}>Адрес:</div>
                  <div className={s.content_item_value}>{user?.address}</div>
                </div>
              )}
              {user?.phone_number && (
                <div className={s.content_item}>
                  <div className={s.content_item_title}>Телефон:</div>
                  <div className={s.content_item_value}>
                    {user?.phone_number}
                  </div>
                </div>
              )}
              <Link to='/updateUser' className={s.btn}>
                <EditOutlined className={s.edit_icon} /> Обновить профиль
              </Link>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default UserInfo;
