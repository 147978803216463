import React from "react";
import Header from "../../components/Header/Header";
import s from "./AboutUs.module.scss";
import Footer from "../../components/Footer/Footer";
import Mission from "../../components/Mission/Mission";
import {
  HomeOutlined,
  FilterOutlined,
  ToolOutlined,
  PullRequestOutlined,
  AlertOutlined,
  TruckOutlined,
  BgColorsOutlined,
  ExperimentOutlined,
  SkinOutlined,
  GoldOutlined,
  CarOutlined,
  ShopOutlined,
  ApartmentOutlined,
  BranchesOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  return (
    <div>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.about_content}>
        <div className={s.text}>
          <h2 className={s.title}>{t("aboutUs")}</h2>
          <div className={s.description}>{t("aboutUsIntro")}</div>

          <div className={s.mission}>
            <div className={s.mission_wrapper}>
              <h3 className={s.h3}>{t("mission")}</h3>
              <div className={s.mission_text}>
                <Mission />
              </div>
            </div>
            <div className={s.mission_wrapper}>
              <h3 className={s.h3}>{t("directions")}</h3>
              <div className={s.introduction}>{t("directionsIntro")}</div>
              <div className={s.mission_text}>
                <div className={s.text_item}>
                  <div>
                    <HomeOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("agro")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <FilterOutlined className={s.icon} />
                    &nbsp;
                  </div>
                  <div>{t("water")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <AlertOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("healthcare")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <TruckOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("auto")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <BgColorsOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("science")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <PullRequestOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("gas")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <ExperimentOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("chemical")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <SkinOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("textile")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <ToolOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("manufacturing")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <GoldOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("mining")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <CarOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("transportation")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <HomeOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("residentialConstruction")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <ShopOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("trade")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <ApartmentOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("infrastructureGrowth")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <LaptopOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("it")}</div>
                </div>
                <div className={s.text_item}>
                  <div>
                    <BranchesOutlined className={s.icon} /> &nbsp;
                  </div>
                  <div>{t("tourism")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default AboutUs;
