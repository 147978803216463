import React from "react";
import s from "./NewsDetails.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useParams } from "react-router-dom";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const NewsDetails = () => {
  const { width } = useWindowDimensions();
  // const news = data.find(it => it.id === Number(params.id))
  const news = useSelector((state) => state.application.newsDetails);
  const { t } = useTranslation();

  const styleObj = {
    lineHeight: 4,
    lineSpacingMultiplier: "2",
    letterSpacing: "1px",
  };

  return (
    <div className={s.news_details}>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.news_content}>
        <div className={s.news_img_wrapper}>
          <img src={news?.image} alt='' className={s.img} />
          <div className={s.news_date}>{news?.created_at}</div>
        </div>
        <div className={s.news_category}>{t("news").toUpperCase()}</div>
        <div className={s.news_title}>{news?.title}</div>
        {/* <div className={s.line}></div> */}
        <div
          className={s.news_description}
          dangerouslySetInnerHTML={{ __html: news?.body }}
          style={styleObj}
        ></div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default NewsDetails;
