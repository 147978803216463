import { Col, Form, Input, message, Row } from "antd";
import React, { useState } from "react";
import s from "./ResetPassword.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { setNewPassword } from "../../redux/ApplicationSlice";
import Header from "../../components/Header/Header";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [form] = useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const params = useParams();
  const { width } = useWindowDimensions();

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message ? message : "Новый OTP код отправлен на вашу почту",
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message ? message : "ОТП код неверный!",
    });
  };

  const requestNewOtp = async (values) => {
    try {
      const res = await dispatch(
        setNewPassword({
          email: values.email,
          new_password1: values.password1,
          new_password2: values.password2,
          uid: params.uid,
          token: params.id,
        })
      );
      if (res.type === "user/setNewPassword/fulfilled") {
        success(res.payload.detail);
        navigate("/login");
      }
      if (res.type === "user/setNewPassword/rejected") {
        error("Такая почта не зарегистрирована");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className={s.login}>
      {contextHolder}
      {width > 970 ? <Header /> : <MobileHeader />}
      <h2 className={s.h2}>
        <img src='/favicon.png' className={s.logo} /> Изменить пароль
      </h2>
      {/* <div className={s.subheader}>
        На вашу почту будет отправлена новая ссылка для активации
      </div> */}
      <Form
        className={s.form}
        layout='vertical'
        form={form}
        onFinish={requestNewOtp}
        style={{ width: "500px" }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name='email'
              label='Введите ваш email'
              rules={[
                {
                  required: true,
                  message: "Введите на ваш email",
                },
                { type: "email", message: "Введите корректный email" },
              ]}
            >
              <input placeholder='Введите вашу почту' className={s.input} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='password1'
              label={<span style={{ fontSize: "12px" }}>Пароль</span>}
              className={s.formItem}
              rules={[
                { required: true, message: "Введите пароль" },
                {
                  min: 8,
                  message: "Пароль должен состоять минимум из 8 символов,",
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                  message:
                    "Пароль должен иметь минимум одну заглавную, одну прописную буквы и цифру",
                },
              ]}
            >
              <Input.Password
                type={showPassword ? "text" : "password"}
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined className={s.icon} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
                suffix={
                  showPassword ? (
                    <EyeOutlined
                      onClick={togglePasswordVisibility}
                      style={{ color: "rgb(248, 116, 64)" }}
                    />
                  ) : (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  )
                }
                placeholder='Введите ваш пароль'
                className={s.password_input}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='password2'
              label={
                <span style={{ fontSize: "12px" }}>Потвердитe пароль</span>
              }
              dependencies={["password"]}
              className={s.formItem}
              rules={[
                { required: true, message: "Введите пароль еще раз" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Пароли не совпадают!"));
                  },
                }),
              ]}
            >
              <Input.Password
                type={showPassword ? "text" : "password"}
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: "rgb(248, 116, 64)" }} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
                suffix={
                  showPassword ? (
                    <EyeOutlined
                      onClick={toggleConfirmPasswordVisibility}
                      className={s.icon}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  )
                }
                placeholder='Введите ваш пароль еще раз'
                className={s.password_input}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <button className={s.enter_btn} type='htmlSubmit'>
            Изменить пароль
          </button>
        </Row>
        <div className={s.no_account}>
          Нет аккаунта? &nbsp;
          <Link to='/register' className={s.register_btn}>
            Зарегестрироваться
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default ResetPassword;
