import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./Chat.module.scss";
import Header from "../../components/Header/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useParams } from "react-router-dom";
import {
  getMessages,
  postMessage,
  setMessageReceiver,
} from "../../redux/ApplicationSlice";
import {
  ArrowLeftOutlined,
  LeftOutlined,
  SendOutlined,
} from "@ant-design/icons";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import ChatSidebar from "../../components/ChatSidebar/ChatSidebar";

const Chat = () => {
  const messages = useSelector((state) => state.application.messages);
  const user = useSelector((state) => state.application.user);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const { width } = useWindowDimensions();
  const applications = useSelector(
    (state) => state.application.applicationsList
  );
  const messageReceiver = useSelector(
    (state) => state.application.messageReceiver
  );

  const sendMessage = async () => {
    const res = await dispatch(
      postMessage({
        id: Number(params.id),
        params: {
          application: Number(params.id),
          message: message.trim(),
        },
      })
    );

    if ((res.type = "user/postMessage/fulfilled")) {
      dispatch(getMessages(Number(params.id)));
      setMessage("");
      dispatch(getMessages(Number(params.id)));
    }
  };
  return (
    <div className={s.wrapper}>
      {width > 970 ? <Header /> : <MobileHeader />}
      {/* <h2 className={s.h2}>Мои сообщения</h2> */}
      <div className={s.chat}>
        <div className={s.sidebar}>
          <ChatSidebar user={user} />
        </div>

        <div className={s.content}>
          <div className={s.messages_head}>
            <div className={s.messages_header}>
              <div className={s.avatar_wrapper}>
                <img src='/assets/noImage.png' alt='' className={s.avatar} />
              </div>
              <div>
                <div className={s.name}>
                  {user.is_admin ? messageReceiver?.name : "AKDF"}
                </div>
                <div className={s.email}>
                  {user.is_admin ? messageReceiver?.email : "admin@mail.ru"}
                </div>
              </div>
            </div>

            {width < 800 && (
              <Link
                to={
                  user.is_admin
                    ? width > 800
                      ? "/adminAccount"
                      : "/chatSidebar"
                    : "/myAccount"
                }
                className={s.back}
              >
                <ArrowLeftOutlined className={s.icon} /> Назад
              </Link>
            )}
          </div>
          <div className={s.messages}>
            {messages?.map((it, index, arr) => (
              <div>
                <div>
                  {index === 0 && (
                    <div className={s.dateWrapper}>
                      <span className={s.date}>
                        {it?.timestamp
                          ?.split(" ")[0]
                          .replace(/-/g, ".")
                          .split(".")
                          .reverse()
                          .join(".")}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <span
                    className={
                      user?.is_admin
                        ? it.role === "admin"
                          ? s.user
                          : s.admin
                        : it.role === "admin"
                        ? s.admin
                        : s.user
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: it.message }}
                      className={
                        user?.is_admin
                          ? it.role === "admin"
                            ? s.user_message
                            : s.admin_message
                          : it.role === "admin"
                          ? s.admin_message
                          : s.user_message
                      }
                    />
                    <span className={s.time}>
                      {it?.timestamp?.split(" ")[1]}
                    </span>
                  </span>
                </div>
                {it?.timestamp?.split(" ")[0] ===
                arr[index + 1]?.timestamp?.split(" ")[0] ? null : (
                  <div className={s.dateWrapper}>
                    <span className={s.date}>
                      {arr[index + 1]?.timestamp

                        ?.split(" ")[0]
                        .replace(/-/g, ".")
                        .split(".")
                        .reverse()
                        .join(".")}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={s.addMessage}>
            <ReactQuill
              value={message}
              onChange={setMessage}
              className={s.editor}
            />
            <div className={s.btn_wrapper}>
              <button className={s.btn} onClick={() => sendMessage()}>
                <SendOutlined className={s.send_icon} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
