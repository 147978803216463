import { Col, Form, message, Row } from "antd";
import React from "react";
import s from "./ForgotPassword.module.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { sendEmailToResetPassword } from "../../../redux/ApplicationSlice";
import Header from "../../../components/Header/Header";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../../components/WindowDimensions/WindowDimensions";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { width } = useWindowDimensions();

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message ? message : "Новый OTP код отправлен на вашу почту",
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message ? message : "ОТП код неверный!",
    });
  };

  const requestNewOtp = async (values) => {
    try {
      const res = await dispatch(
        sendEmailToResetPassword({
          email: values.email,
        })
      );

      if (res.type === "user/sendEmailToResetPassword/fulfilled") {
        success(res.payload.detail);
      } else {
        error(res.payload.detail);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className={s.login}>
      {contextHolder}
      {width > 970 ? <Header /> : <MobileHeader />}
      <h2 className={s.h2}>
        <img src='/favicon.png' className={s.logo} /> Сбросить пароль
      </h2>
      <div className={s.subheader}>
        На вашу почту будет отправлена новая ссылка для сброса пароля
      </div>
      <Form
        className={s.form}
        layout='vertical'
        form={form}
        onFinish={requestNewOtp}
        style={{ width: "500px" }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name='email'
              label='Введите ваш email'
              rules={[
                {
                  required: true,
                  message: "Введите на ваш email",
                },
                { type: "email", message: "Введите корректный email" },
              ]}
            >
              <input placeholder='Введите вашу почту' className={s.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <button className={s.enter_btn} type='htmlSubmit'>
            Получить ссылку
          </button>
        </Row>
        <div className={s.no_account}>
          Нет аккаунта? &nbsp;
          <Link to='/register' className={s.register_btn}>
            Зарегестрироваться
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPassword;
