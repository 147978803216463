import React from "react";
import s from "./ChatSidebar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMessages, setMessageReceiver } from "../../redux/ApplicationSlice";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Header from "../Header/Header";
import MobileHeader from "../MobileHeader/MobileHeader";

const ChatSidebar = () => {
  const user = useSelector((state) => state.application.user);
  const applications = useSelector(
    (state) => state.application.applicationsList
  );
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  return (
    <div className={s.sidebar}>
      {width < 800 && <MobileHeader />}
      {
        <div className={s.back}>
          <div className={s.users}>Пользователи</div>{" "}
          <Link to={user.is_admin ? "/adminAccount" : "/myAccount"}>
            <ArrowLeftOutlined className={s.icon} /> Назад
          </Link>
        </div>
      }
      {user.is_admin ? (
        <div>
          {applications.map((it) => (
            <Link
              to={`/chat/${it.id}`}
              className={s.sidebar_sender}
              onClick={() => {
                dispatch(getMessages(it.id));
                dispatch(
                  setMessageReceiver({
                    name: it?.full_name,
                    email: it?.email,
                  })
                );
              }}
            >
              <div className={s.avatar_wrapper}>
                <img src='/assets/noImage.png' alt='' className={s.avatar} />
              </div>
              <div>
                <div className={s.sender_name}>
                  {it?.full_name || it?.company}
                </div>
                <div className={s.last_message}>
                  <div className={s.message}>{it?.last_message}</div>
                  <div className={s.last_message_date}>
                    {it?.last_message_date}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className={s.personalInfo}>
          {width < 1000 ? (
            <Link to='/myAccount'>
              <ArrowLeftOutlined />
            </Link>
          ) : null}
          <div className={s.mobile_header}>
            <div className={s.img_wrapper}>
              <img src='/assets/noImage.png' alt='' className={s.img} />
            </div>
            <div className={s.content_item}>{user?.email}</div>
          </div>

          {width > 1000 ? (
            <div className={s.btns}>
              <Link to='/myAccount' className={s.link_btn}>
                Мои объявления
              </Link>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ChatSidebar;
