import React, { useState } from "react";
import s from "./FAQ.module.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  QuestionCircleOutlined,
  EditOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const [openedAccordionKey, setOpenedAccordionKey] = useState(0);
  const { t } = useTranslation();

  return (
    <div className={s.faq}>
      <div className={s.wrapper}>
        <h2 className={s.h2} data-aos='fade-up' data-aos-duration='2000'>
          {t("faq")}&nbsp;
          <QuestionCircleOutlined className={s.question_icon} />{" "}
        </h2>

        <div className={s.subtitle} data-aos='fade-up' data-aos-duration='3000'>
          <EditOutlined /> &nbsp;{t("faqIntro")}
        </div>
        <Accordion
          allowZeroExpanded
          className={s.accordion}
          allowMultipleExpanded={false}
        >
          <AccordionItem key={1} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(1)}
            >
              <AccordionItemButton className={s.btn}>
                <div>1. {t("question1")}</div>
                <div>
                  {openedAccordionKey === 1 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer1")}
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem key={2} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(2)}
            >
              <AccordionItemButton className={s.btn}>
                <div>2. {t("question2")}</div>
                <div>
                  {openedAccordionKey === 2 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer2")}
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem key={3} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(3)}
            >
              <AccordionItemButton className={s.btn}>
                <div>3. {t("question3")}</div>
                <div>
                  {openedAccordionKey === 3 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer3")}
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem key={4} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(4)}
            >
              <AccordionItemButton className={s.btn}>
                <div>4. {t("question4")}</div>
                <div>
                  {openedAccordionKey === 4 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer4")}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={5} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(5)}
            >
              <AccordionItemButton className={s.btn}>
                <div>5. {t("question5")}</div>
                <div>
                  {openedAccordionKey === 5 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer5")}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={6} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(6)}
            >
              <AccordionItemButton className={s.btn}>
                <div>6. {t("question6")}</div>
                <div>
                  {openedAccordionKey === 6 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answ61")} <br />
              {t("answ62")} <br />
              {t("answ63")} <br />
              {t("answ64")} <br />
              {t("answ65")} <br />
              {t("answ66")} <br />
              {t("answ67")}
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem key={7} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(7)}
            >
              <AccordionItemButton className={s.btn}>
                <div>7. {t("question7")} </div>
                <div>
                  {openedAccordionKey === 7 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer71")} <br />
              {t("answer72")} <br />
              {t("answer73")} <br />
              {t("answer74")} <br />
              {t("answer75")} <br />
              {t("answer76")} <br />
              {t("answer77")} <br />
              {t("answer78")} <br />
              {t("answer79")} <br />
              {t("answer710")} <br />
              {t("answer711")} <br />
              {t("answer712")}  <br />
              {t("answer713")}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={8} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(8)}
            >
              <AccordionItemButton className={s.btn}>
                <div>8. {t("question8")}</div>
                <div>
                  {openedAccordionKey === 8 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer80")} <br />
              {t("answer81")} <br />
              {t("answer82")}
              <br />
              {t("answer83")}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={9} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(9)}
            >
              <AccordionItemButton className={s.btn}>
                <div>9. {t("question9")}</div>
                <div>
                  {openedAccordionKey === 9 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer9")}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={10} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(10)}
            >
              <AccordionItemButton className={s.btn}>
                <div>10. {t("question10")}</div>
                <div>
                  {openedAccordionKey === 10 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer10")}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={11} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(11)}
            >
              <AccordionItemButton className={s.btn}>
                <div>11. {t("question11")}</div>
                <div>
                  {openedAccordionKey === 11 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer11")}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem key={12} className={s.item}>
            <AccordionItemHeading
              className={s.heading}
              onClick={(e) => setOpenedAccordionKey(12)}
            >
              <AccordionItemButton className={s.btn}>
                <div>12. {t("question12")}</div>
                <div>
                  {openedAccordionKey === 12 ? (
                    <CaretUpOutlined className={s.icon} />
                  ) : (
                    <CaretDownOutlined className={s.icon} />
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={s.panel}>
              {t("answer12")}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
