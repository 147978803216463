import { Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getApplicationDetails,
  getMessages,
} from "../../redux/ApplicationSlice";
import s from "./UserMobileTable.module.scss";
import { FormOutlined } from "@ant-design/icons";

const UserMobileTable = ({ users, isOpen, setIsOpen }) => {
  // const users = useSelector((state) => state.application.usersList);
  const dispatch = useDispatch();

  const columns = [
    {
      title: "№",
      dataIndex: `number`,
      width: "20px",
    },
    {
      title: "Общая информация",
      dataIndex: `info`,
    },
  ];
  return (
    <div className={s.table}>
      <Table
        bordered
        pagination={false}
        columns={columns}
        className={"mobile_tender_table"}
        dataSource={
          Array.isArray(users) &&
          users.map((it, index) => ({
            number: <div className={"mobile_td"}>{index + 1}</div>,
            info: (
              <div>
                <div className='tender_item'>
                  <div className='item_title'>ФИО:</div>
                  <div className={s.item_value} style={{ fontWeight: 600 }}>
                    {it?.full_name}
                  </div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'> Должность:</div>
                  <div className={s.item_value}>{it?.job_title}</div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'>Компания:</div>
                  <div className={s.item_value}>{it?.company}</div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'>Email:</div>
                  <div className={s.item_value}>{it?.email}</div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'>Телефон:</div>
                  <div className={s.item_value}>{it?.phone_number}</div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'>Адрес:</div>
                  <div className={s.item_value}>{it?.address}</div>
                </div>
              </div>
            ),
            application: (
              <div
                className={s.more}
                onClick={() => {
                  dispatch(getApplicationDetails(it?.id));
                  setIsOpen(true);
                }}
              >
                <FormOutlined
                  className={s.chatIcon}
                  style={{ fontSize: "16px", marginLeft: "15px" }}
                />
              </div>
            ),
            messages: (
              <Link
                to={`/chat/${it.id}`}
                className={s.link}
                onClick={() => dispatch(getMessages(it.id))}
              >
                <img src='/assets/chat.png' className={s.chatIcon} />
              </Link>
            ),
          }))
        }
      />
    </div>
  );
};

export default UserMobileTable;
