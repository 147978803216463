import React, { useState } from "react";
import s from "./Application.module.scss";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Upload,
  Tooltip,
  Checkbox,
} from "antd";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import {
  getApplicationsList,
  getMyApplications,
  sendApplication,
} from "../../redux/ApplicationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  BankFilled,
  CalendarFilled,
  ContactsFilled,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Loading from "../Loading/Loading";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { TextArea } = Input;

const props = {
  name: "file",
  action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  progress: {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    strokeWidth: 3,
    format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
  },
};

const ApplicationDrawer = ({ open, onClose }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const loading = useSelector((state) => state.application.loading);
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const lang = useSelector((state) => state.application.lang);

  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [file, setFile] = useState([]);
  const user = useSelector((state) => state.application.user);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Вы отправили информацию о проекте!",
    });
  };

  const sendForm = async (values) => {
    const data = new FormData();

    data.append("full_name", values.name);
    data.append("company", values.companyName);
    data.append("phone", values.phone);
    data.append("email", values.email);
    data.append("project_goal", values.projectGoal);
    data.append("project_description", values.projectDescription);
    data.append("industry.industry", values.projectField);
    data.append("total_cost", values.projectValue);
    data.append("intended_use", values.intendedUse);
    data.append("existing_business_description", values.businessDescription);
    data.append("requested_amount", values.requestedAmount);
    data.append("pledge", values.pledge);
    data.append("existing_loans", values.currentLoans);

    if (file.fileList && file.fileList.length > 0) {
      file.fileList.forEach((fileItem) => {
        data.append("accompanying_documents", fileItem.originFileObj);
      });
    }

    const res = await dispatch(sendApplication(data));

    if (res.type === "application/sendApplication/fulfilled") {
      success();
      form.resetFields();
      setFile([]);

      if (!user.is_admin) {
        dispatch(getMyApplications());
      } else {
        dispatch(dispatch(getApplicationsList()));
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {contextHolder}
      <Drawer
        title={t("pre-application")}
        width={width > 1000 ? "90%" : "100%"}
        onClose={onClose}
        open={open}
      >
        <div className={s.intro}>
          <strong>{t("commitmentToCompleteApplication")}</strong> <br />
          {t("applicationIntro")}
        </div>
        <Form
          form={form}
          layout='vertical'
          className={s.form}
          encType='multipart/form-data'
          onFinish={sendForm}
        >
          <div className={s.section}>
            <h3 className={s.h2}>
              <ContactsFilled className={s.icon} /> {t("aboutApplicant")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='companyName'
                  label={
                    <div>
                      {t("company")}{" "}
                      <Tooltip
                        placement='top'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleCompany")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("enterCompanyName"),
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterCompanyName")}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='name'
                  label={
                    <div>
                      {t("name")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleName")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("enterFullName"),
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterFullName")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='phone'
                  label={
                    <div>
                      {t("contactPhone")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("examplePhone")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("enterPhoneNumber"),
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterPhoneNumber")}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='email'
                  label={
                    <div>
                      {t("email")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              moloko@gmail.com
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    { required: true, message: t("enterEmail") },
                    { type: "email", message: t("enterValidEmail") },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterEmail")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <CalendarFilled className={s.icon} /> {t("aboutProject")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectGoal'
                  label={
                    <div>
                      {t("goal")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleGoal")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("enterProjectGoal"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    placeholder={t("enterProjectGoal")}
                    style={{ height: "100px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectDescription'
                  label={
                    <div>
                      {t("projectDescription")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleDesc")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("enterProjectDescription"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px" }}
                    placeholder={t("enterProjectDescription")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectField'
                  label={
                    <div>
                      {t("projectIndustry")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>{t("agro")}</div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("selectIndustry"),
                    },
                  ]}
                >
                  <Select
                    placeholder={t("selectIndustry")}
                    className={s.form_input}
                    style={{ padding: "0", borderRadius: "10px" }}
                  >
                    <Option value='агропромышленный комплекс'>
                      {t("agro")}
                    </Option>
                    <Option value='сектор водоснабжения'>{t("water")}</Option>
                    <Option value='сектор здравоохранения'>
                      {t("healthcare")}
                    </Option>
                    <Option value='автомобильная промышленность'>
                      {t("auto")}
                    </Option>
                    <Option value='наука, исследования и технология'>
                      {t("science")}
                    </Option>
                    <Option value='газопромышленный комплекс'>
                      {t("gas")}
                    </Option>
                    <Option value='химическая и нефтехимическая промышленность'>
                      {t("chemical")}
                    </Option>
                    <Option value='швейная и текстильная промышленность'>
                      {t("textile")}
                    </Option>
                    <Option value='обрабатывающая промышленность'>
                      {t("manufacturing")}
                    </Option>
                    <Option value='горнодобывающая и металлургическая промышленность'>
                      {t("mining")}
                    </Option>
                    <Option value='транспорт'>{t("transportation")}</Option>
                    <Option value='жилищное строительство'>
                      {t("residentialConstruction")}
                    </Option>
                    <Option value='торговля'>{t("trade")}</Option>
                    <Option value='развитие инфраструктуры'>
                      {t("infrastructureGrowth")}
                    </Option>
                    <Option value='информационные технологии'>{t("it")}</Option>
                    <Option value='развитие туризма'>{t("tourism")}</Option>
                    <Option value='прочие'>{t("other")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectValue'
                  label={
                    <div>
                      {t("totalAmount")}
                      <Tooltip
                        placement='topLeft'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>650 000 USD</div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("enterProjectAmountandCurrency"),
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterProjectAmountandCurrency")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='intendedUse'
                  className={s.formItem}
                  label={
                    <div>
                      {t("purpose")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        arrow={false}
                        placement='topLeft'
                        style={{
                          width: width > 600 ? "400px" : "320px",
                        }}
                        height={450}
                        width={320}
                        title={
                          <div
                            className={s.tooltip}
                            style={{
                              maxWidth: "400px",
                              width: width > 600 ? "400px" : "240px",
                            }}
                          >
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              <table className={s.table}>
                                <tr>
                                  <th style={{ width: "20px" }}>№</th>
                                  <th
                                    style={{
                                      width: width > 600 ? "250px" : "125px",
                                    }}
                                  >
                                    {t("title")}
                                  </th>
                                  <th>{t("amount")}</th>
                                </tr>
                                <tbody>
                                  <tr>
                                    <td>1. </td>
                                    <td> {t("acquisitionEquipment")} </td>
                                    <td>250 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>2.</td>
                                    <td>
                                      {t("acquisitionSpecializedEquipment")}
                                    </td>
                                    <td>70 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>3.</td>
                                    <td> {t("constructionWork")} </td>
                                    <td>330 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>4.</td>
                                    <td>…….</td>
                                    <td>…….</td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <th>{t("total")}</th>
                                    <th>650 000 USD</th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("specifyLoanPurpose"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "200px" }}
                    placeholder={t("specifyLoanPurpose")}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='businessDescription'
                  label={
                    <div>
                      {t("currentBusinessDescription")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        placement='topLeft'
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleCurrentBusiness")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("describeCurrentBusiness"),
                    },
                  ]}
                >
                  <TextArea
                    style={{
                      marginTop: lang === "ru" ? "28px" : "0",
                      height: "200px",
                    }}
                    className={s.form_input}
                    placeholder={t("describeCurrentBusiness")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <BankFilled className={s.icon} />
              &nbsp;
              {t("aboutLoan")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='requestedAmount'
                  className={s.formItem}
                  label={
                    <div>
                      {t("requestedAmount")}
                      <Tooltip
                        placement='topLeft'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleRequestedAmount")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("enterRequestedAmount"),
                    },
                  ]}
                >
                  <TextArea
                    style={{
                      minHeight: "100px",

                      verticalAlign: "top",
                      height: "100px",
                    }}
                    className={s.form_input}
                    placeholder={t("enterRequestedAmount")}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='currentLoans'
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("enterExistingLoans"),
                    },
                  ]}
                  label={
                    <div>
                      {t("existingLoans")}
                      <Tooltip
                        placement='topRight'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")} №1:
                            </div>
                            <div className={s.tooltipContent}>
                              {t("currentLoan")} <br />
                              <div>
                                <div
                                  className={s.tooltipTitle}
                                  style={{ marginTop: "5px" }}
                                >
                                  <ExclamationCircleOutlined
                                    className={s.tooltip_text_icon}
                                  />
                                  {t("example")} №2:
                                </div>
                                <div className={s.tooltipContent}>
                                  {t("noLoans")}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px" }}
                    placeholder={t("enterExistingLoans")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='pledge'
                  label={
                    <div>
                      {t("collateralInformation")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        placement='topLeft'
                        title={
                          <div
                            className={s.tooltip}
                            style={{
                              maxWidth: "400px",
                              width: width > 600 ? "400px" : "240px",
                            }}
                          >
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              <table className={s.table}>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "20px" }}>1. </td>
                                    <td
                                      style={{
                                        width: width > 600 ? "200px" : "120px",
                                      }}
                                    >
                                      {t("nonresidential")}
                                    </td>
                                    <td>400 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>2.</td>
                                    <td>{t("residentialHouse")}</td>
                                    <td>50 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>3.</td>
                                    <td>{t("apartment")} </td>
                                    <td>70 000 USD</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("describePledge"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "200px" }}
                    placeholder={t("describePledge")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='accompanying_documents'
                  label={t("uploadDocuments")}
                >
                  <Upload
                    name='accompanying_documents'
                    multiple
                    {...props}
                    onChange={(e) => setFile(e)}
                  >
                    <Button icon={<UploadOutlined />} className={s.btn}>
                      {t("upload")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name={"checkbox"}
                  rules={[
                    {
                      required: isChecked ? false : true,
                      message: t("consentRequired"),
                    },
                  ]}
                >
                  <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
                    {t("processingPersonalData")}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Space className={s.btns}>
            {width > 475 ? (
              <Button onClick={onClose} className={s.btn}>
                {t("cancel")}
              </Button>
            ) : null}
            <Button className={s.btn2} htmlType='submit'>
              {t("send")}
            </Button>
          </Space>
        </Form>
        <div className={s.loader}>
          {loading ? <Loading loading={loading} /> : null}
        </div>
      </Drawer>
    </div>
  );
};

export default ApplicationDrawer;
