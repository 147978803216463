import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import s from "./ConfirmEmail.module.scss";
import Footer from "../../components/Footer/Footer";
import { useDispatch } from "react-redux";
import { confirmEmail } from "../../redux/ApplicationSlice";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const ConfirmEmail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [message, setMessage] = useState();

  useEffect(() => {
    const getConfirmMessage = async () => {
      const res = await dispatch(confirmEmail(params.id));
      console.log("res", res);

      if (res.type === "user/confirmEmail/fulfilled") {
        setMessage({
          message: res.payload.detail,
          status: res.meta.requestStatus,
        });
      }

      if (res.type === "user/confirmEmail/rejected") {
        setMessage({ message: res.payload, status: res.meta.requestStatus });
      }
    };
    getConfirmMessage();
  }, [dispatch]);

  return (
    <div>
      <Header />
      <div className={s.confirm}>
        <div className={s.text}>
          {message && message.status === "fulfilled" ? (
            <div className={s.success}>
              <CheckCircleOutlined className={s.green} />
              {message?.message}
              <div>
                <Link to='/login' className={s.enter_btn} type='htmlSubmit'>
                  Войти
                </Link>
              </div>
            </div>
          ) : (
            <div className={s.success}>
              <ExclamationCircleOutlined className={s.yellow} />
              {message?.message}
              <div>
                <Link to='/login' className={s.enter_btn} type='htmlSubmit'>
                  Войти
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ConfirmEmail;
