import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import s from "./ApprovedProjects.module.scss";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useTranslation } from "react-i18next";

const ApprovedProjects = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  return (
    <div>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.projects}>
        {/* <img src='/assets/coming-soon.png' alt='' className={s.icon}/> */}
        <div>
          <img src='/favicon.png' className={s.favicon} />
        </div>
        <div className={s.text}>{t("noApprovedProjects")}</div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default ApprovedProjects;
