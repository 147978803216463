import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import s from "./News.module.scss";
import NewsItem from "../../components/NewsItem/NewsItem";
import Footer from "../../components/Footer/Footer";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../redux/ApplicationSlice";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";

const News = () => {
  const { height, width } = useWindowDimensions();
  const data = useSelector((state) => state.application.news);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);

  return (
    <>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.news}>
        <h1 className={s.header}>{t("news")}</h1>

        {/* <div className={s.divider}></div>	 */}
        {data?.map((it) => (
          <NewsItem news={it} />
        ))}
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default News;
