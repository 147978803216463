import { Col, Form, Input, message, Row } from "antd";
import React, { useState } from "react";
import s from "./Register.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useForm } from "antd/es/form/Form";
import Header from "../../components/Header/Header";
import { register } from "../../redux/ApplicationSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const [form] = useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { width } = useWindowDimensions();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const registerUser = async (values) => {
    try {
      const res = await dispatch(
        register({
          email: values.email,
          password1: values.password1,
          password2: values.password2,
        })
      );

      console.log("res", res);

      if (res.type === "user/register/fulfilled") {
        success(
          "Письмо было выслано на вашу почту, пройти по ссылке для активации аккаунта"
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }

      if (res.type === "user/register/rejected") {
        error(res.payload);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className={s.login}>
      {width > 970 ? <Header /> : <MobileHeader />}
      {contextHolder}
      <h2 className={s.h2}>
        <img src='/favicon.png' className={s.logo} /> Зарегистрироваться
      </h2>
      <Form
        className={s.form}
        layout='vertical'
        form={form}
        onFinish={registerUser}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name='email'
              label={<span style={{ fontSize: "12px" }}>Email</span>}
              className={s.formItem}
              rules={[
                { required: true, message: "Введите email" },
                { type: "email", message: "Укажите корректный email" },
              ]}
            >
              <input placeholder='Введите вашу почту' className={s.input} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='password1'
              label={<span style={{ fontSize: "12px" }}>Пароль</span>}
              className={s.formItem}
              rules={[
                { required: true, message: "Введите пароль" },
                {
                  min: 8,
                  message: "Пароль должен состоять минимум из 8 символов,",
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                  message:
                    "Пароль должен иметь минимум одну заглавную, одну прописную буквы и цифру",
                },
              ]}
            >
              <Input.Password
                type={showPassword ? "text" : "password"}
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined className={s.icon} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
                suffix={
                  showPassword ? (
                    <EyeOutlined
                      onClick={togglePasswordVisibility}
                      style={{ color: "rgb(248, 116, 64)" }}
                    />
                  ) : (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  )
                }
                placeholder='Введите ваш пароль'
                className={s.password_input}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='password2'
              label={
                <span style={{ fontSize: "12px" }}>Потвердитe пароль</span>
              }
              dependencies={["password"]}
              className={s.formItem}
              rules={[
                { required: true, message: "Введите пароль еще раз" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Пароли не совпадают!"));
                  },
                }),
              ]}
            >
              <Input.Password
                type={showPassword ? "text" : "password"}
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: "rgb(248, 116, 64)" }} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
                suffix={
                  showPassword ? (
                    <EyeOutlined
                      onClick={toggleConfirmPasswordVisibility}
                      className={s.icon}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  )
                }
                placeholder='Введите ваш пароль еще раз'
                className={s.password_input}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <button className={s.enter_btn} type='htmlSubmit'>
            Зарегистрироваться
          </button>
        </Row>
        <div className={s.no_account}>
          Уже есть аккаунт? &nbsp;
          <Link to='/login' className={s.register_btn}>
            Войти
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default Register;
