import React from "react";
import { Steps } from "antd";
import "./Mission.scss";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";

const Mission = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={"introduction"}>{t("missionIntro")}</div>
      <Steps
        progressDot
        current={5}
        direction='vertical'
        items={[
          { title: t("mission1") },
          { title: t("mission2") },
          { title: t("mission3") },
          { title: t("mission4") },
          { title: t("mission5") },
        ]}
      />
      <ScrollToTop />
    </>
  );
};

export default Mission;
