import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Link, useParams } from "react-router-dom";
import {
  AuditOutlined,
  CaretDownFilled,
  CaretRightFilled,
  ContactsOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FileSyncOutlined,
  GlobalOutlined,
  HomeOutlined,
  IdcardOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import ApplicationDrawer from "../ApplicationDrawer/ApplicationDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprisers,
  getBoardMembers,
  getConsultants,
  getContacts,
  getCouncilMembers,
  getInsuranceCompanies,
  getNews,
  getNewsDetails,
  getReports,
  getTenderDetails,
  getTenders,
  logout,
  setLang,
} from "../../redux/ApplicationSlice";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { height } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.application.lang);
  const params = useParams();
  const { t } = useTranslation();
  const user = useSelector((state) => state.application.user);

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(getNews());
    dispatch(getTenders());
    dispatch(getBoardMembers());
    dispatch(getCouncilMembers());
    dispatch(getContacts());
    dispatch(getConsultants());
    dispatch(getApprisers());
    dispatch(getReports());
    dispatch(getInsuranceCompanies());

    params.id && dispatch(getTenderDetails(Number(params.id)));
    params.id && dispatch(getNewsDetails(Number(params.id)));
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const redirectToPage = (page) => {
    if (page === "about") {
      navigate("/about");
    }
    if (page === "team") {
      navigate("/team");
    }
    if (page === "reports") {
      navigate("/reports");
    }
    if (page === "companies") {
      navigate("/companies");
    }
    if (page === "consultants") {
      navigate("/consultants");
    }
    if (page === "vacancy") {
      navigate("/vacancies");
    }
  };

  const options1 = (
    <Menu>
      <Menu.Item key='1' onClick={(e) => redirectToPage("about")}>
        <HomeOutlined className={"icon"} />
        {t("aboutUs")}
      </Menu.Item>
      <Menu.Item key='2' onClick={(e) => redirectToPage("team")}>
        <UserOutlined className={"icon"} />
        {t("management")}
      </Menu.Item>
      <Menu.Item key='3' onClick={(e) => redirectToPage("reports")}>
        <FileProtectOutlined className={"icon"} />
        {t("policies")}
      </Menu.Item>
      <Menu.Item key='4' onClick={(e) => redirectToPage("vacancy")}>
        <UserAddOutlined className={"icon"} />
        {t("vacancies")}
      </Menu.Item>
    </Menu>
  );

  const options2 = (
    <Menu>
      <Menu.Item key='1'>
        <Link to='/apprisers'>
          <IdcardOutlined className={"icon"} />
          {t("accreditedAppraisers")}
        </Link>
      </Menu.Item>
      <Menu.Item key='2'>
        <Link to='/consultants'>
          <ContactsOutlined className={"icon"} /> {t("accreditedConsultants")}
        </Link>
      </Menu.Item>
      <Menu.Item key='3'>
        <Link to='/insuranceCompanies'>
          <ContactsOutlined className={"icon"} />{" "}
          {t("accreditedInsuranceCompanies")}
        </Link>
      </Menu.Item>
      <Menu.Item key='4'>
        <Link to='/partnersPage'>
          <TeamOutlined className={"icon"} />
          {t("partners")}
        </Link>
      </Menu.Item>
    </Menu>
  );

  const options4 = (
    <Menu>
      <Menu.Item key='1'>
        <div
          onClick={() => {
            dispatch(setLang("ru"));
            changeLang("ru");
          }}
        >
          {/* <IdcardOutlined className={"icon"} /> */}
          RU
        </div>
      </Menu.Item>
      <Menu.Item key='2'>
        <div
          onClick={() => {
            dispatch(setLang("en"));
            changeLang("en");
          }}
        >
          {/* <ContactsOutlined className={"icon"} />  */}
          EN
        </div>
      </Menu.Item>
    </Menu>
  );

  const options5 = (
    <Menu>
      {localStorage.getItem("access_token") && (
        <Menu.Item key='2'>
          {/* <Link to='/myAccount'> */}
          <div
            onClick={() =>
              user?.email
                ? navigate(user.is_admin ? "/adminAccount" : "/myAccount")
                : navigate("/login")
            }
          >
            <UserOutlined className={"icon"} />
            {t("personalAccount")}
          </div>

          {/* </Link> */}
        </Menu.Item>
      )}
      {localStorage.getItem("access_token") ? (
        <Menu.Item key='3'>
          <div
            onClick={() => {
              dispatch(logout());
              navigate("/");
            }}
          >
            <LogoutOutlined className={"icon"} />
            {t("logout")}
          </div>
        </Menu.Item>
      ) : (
        <Menu.Item key='1'>
          <Link to='/login'>
            <UserOutlined className={"icon"} />
            {t("login")}
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  const options3 = (
    <Menu>
      <Menu.Item key='2'>
        <div onClick={() => showDrawer(true)}>
          {" "}
          <FileSyncOutlined className={"icon"} /> {t("application")}
        </div>
      </Menu.Item>
      <Menu.Item key='1'>
        <Link to='/requirements'>
          <FilePdfOutlined className={"icon"} /> {t("termsOfInvestmentLending")}
        </Link>
      </Menu.Item>
      <Menu.Item key='3'>
        <Link to='/approvedProjects'>
          <AuditOutlined className={"icon"} />
          {t("approvedProjects")}
        </Link>
      </Menu.Item>
      <Menu.Item key='4'>
        <Link to='/realizedProjects'>
          <FileDoneOutlined className={"icon"} />
          {t("completedProjects")}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='header'>
      <div className='logo'>
        <Link to='/' className='logo_link'>
          <img
            src={lang === "ru" ? "/dark_logo.png" : "/akdf_logo(eng).png"}
            className='header_logo'
          />
        </Link>
      </div>
      <div className={"menu_wrapper"}>
        <ul className={"menu"}>
          <Dropdown overlay={options1}>
            <ol className={location.pathname === "/about" ? "active" : ""}>
              {t("aboutUs")}
            </ol>
          </Dropdown>
          <Dropdown overlay={options3}>
            <ol className={location.pathname === "/projects" ? "active" : ""}>
              {t("projects")}
            </ol>
          </Dropdown>

          <ol className={location.pathname === "/tenders" ? "active" : ""}>
            <Link to='/tenders'>{t("tenders")}</Link>
          </ol>

          <Dropdown overlay={options2}>
            <ol className={location.pathname === "/about" ? "active" : ""}>
              {t("partners")}
            </ol>
          </Dropdown>

          <ol className={location.pathname === "/news" ? "active" : ""}>
            <Link to='/news' onClick={() => dispatch(getNews())}>
              {t("news")}
            </Link>
          </ol>
          <Dropdown overlay={options4}>
            <ol className={location.pathname === "/news" ? "active" : ""}>
              <div>
                <GlobalOutlined />
                &nbsp;{lang === "en" ? "EN" : "RU"}
              </div>
            </ol>
          </Dropdown>

          <Dropdown overlay={options5}>
            <ol>
              {user?.email ? (
                <div className={"img_wrapper"}>
                  <img src='/assets/noImage.png' className={"img"} />{" "}
                  <CaretDownFilled />
                </div>
              ) : (
                t("login")
              )}
            </ol>
          </Dropdown>
        </ul>
      </div>
      <ApplicationDrawer open={open} onClose={onClose} />
    </div>
  );
};

export default Header;
