import React from "react";
import s from "./NewsItem.module.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNewsDetails } from "../../redux/ApplicationSlice";
import { useTranslation } from "react-i18next";

const NewsItem = ({ news }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Link
      to={`/news/${news.id}`}
      onClick={() => dispatch(getNewsDetails(news.id))}
    >
      <div className={s.newsItem}>
        <div className={s.image_wrapper}>
          <img src={news.image} className={s.img} alt='' />
        </div>
        <div className={s.text}>
          <div className={s.title}>{news.title}</div>
          <div
            className={s.description}
            dangerouslySetInnerHTML={{ __html: news?.body }}
          >
            {news.description}
          </div>
          {/* <div className={s.date}>{news.date}</div> */}
          <div className={s.btn_wrapper}>
            <button className={s.btn}>{t("more")}</button>
          </div>
        </div>
        <div className={s.date}>{news.created_at}</div>
      </div>
    </Link>
  );
};

export default NewsItem;
