import { Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMessages } from "../../redux/ApplicationSlice";
import s from "./ApplicationsMobileTable.module.scss";

const ApplicationsMobileTable = () => {
  const applications = useSelector((state) => state.application.myApplications);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "№",
      dataIndex: `number`,
    },
    {
      title: "Общая информация",
      dataIndex: `info`,
    },
    {
      title: "",
      dataIndex: `messages`,
      width: "20px",
    },
  ];

  return (
    <div className={s.table}>
      <Table
        bordered
        pagination={false}
        columns={columns}
        className={"mobile_tender_table"}
        dataSource={
          Array.isArray(applications) &&
          applications.map((it, index) => ({
            number: <div className={"mobile_td"}>{index + 1}</div>,
            info: (
              <div>
                <div className='tender_item'>
                  <div className='item_title'>Дата:</div>
                  <div className='item_value'>
                    {it?.created_at
                      .split(" ")[0]
                      .split("-")
                      .reverse()
                      .join(".")}
                  </div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'>Компания:</div>
                  <div className='item_value'>{it?.company}</div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'>Сумма:</div>
                  <div className='item_value'>{it.requested_amount}</div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'>Цель кредита:</div>
                  <div className='item_value'>{it.intended_use}</div>
                </div>
                <div className='tender_item'>
                  <div className='item_title'>Статус:</div>
                  <div className='item_value'>{it.status}</div>
                </div>
              </div>
            ),
            messages: (
              <Link
                to={`/chat/${it.id}`}
                className={s.link}
                onClick={() => dispatch(getMessages(it.id))}
              >
                <img src='/assets/chat.png' className={s.chatIcon} />
              </Link>
            ),
          }))
        }
      />
    </div>
  );
};

export default ApplicationsMobileTable;
