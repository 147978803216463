import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from "./locale/en.json";
import ruJSON from "./locale/ru.json";
import { store } from "./redux/store";

const updateLanguage = () => {
  const currentLanguage = store.getState().application.lang || "ru";
  i18n.changeLanguage(currentLanguage);
};

store.subscribe(() => {
  updateLanguage();
});

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    ru: { ...ruJSON },
  },
  lng: store.getState().application.lang,
  fallbackLng: "ru",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
