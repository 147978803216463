import { Col, Form, Input, message, Row } from "antd";
import React, { useState } from "react";
import s from "../../pages/Login/Login.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useForm } from "antd/es/form/Form";
import Header from "../../components/Header/Header";
import {
  getMyApplications,
  getProfileInfo,
  login,
} from "../../redux/ApplicationSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [form] = useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { width } = useWindowDimensions();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Неправильный логин или пароль!",
    });
  };

  const loginUser = async (values) => {
    try {
      const res = await dispatch(
        login({
          email: values.email,
          password: values.password,
        })
      );

      if (res.type === "user/login/fulfilled") {
        dispatch(getProfileInfo());
        dispatch(getMyApplications());
        if (res.payload.is_admin) {
          navigate("/adminAccount");
        }

        if (!res.payload.is_admin) {
          navigate("/myAccount");
        }
      }

      if (res.type === "user/login/rejected") {
        error();
      }
    } catch (err) {
      console.log("err", err);
      // if(err.payload === '"Request failed with status code 400"')
    }
  };

  return (
    <div className={s.login}>
      {contextHolder}
      {width > 970 ? <Header /> : <MobileHeader />}
      <h2 className={s.h2}>
        <img src='/favicon.png' className={s.logo} /> Войти в личный кабинет
      </h2>
      <Form
        className={s.form}
        layout='vertical'
        form={form}
        onFinish={loginUser}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name='email'
              label='Email'
              rules={[
                { required: true, message: "Введите email" },
                { type: "email", message: "Укажите корректный email" },
              ]}
            >
              <input placeholder='Введите вашу почту' className={s.input} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='password'
              label='Пароль'
              rules={[
                { required: true, message: "Введите пароль" },
                // {
                //   min: 8,
                //   message: "Пароль должен состоять минимум из 8 символов",
                // },
                // {
                //   pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                //   message:
                //     "Password must include at least one uppercase letter, one lowercase letter, and one number!",
                // },
              ]}
            >
              <Input.Password
                type={showPassword ? "text" : "password"}
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined className={s.icon} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
                suffix={
                  showPassword ? (
                    <EyeOutlined
                      onClick={togglePasswordVisibility}
                      style={{ color: "rgb(248, 116, 64)" }}
                    />
                  ) : (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  )
                }
                placeholder='Введите ваш пароль'
                className={s.password_input}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className={s.row}>
          <Link to='/forgotPassword' className={s.forgetPassword}>
            Забыли пароль?
          </Link>
        </div>
        <Row>
          <button className={s.enter_btn} type='htmlSubmit'>
            Войти
          </button>
        </Row>
        <div className={s.no_account}>
          Нет аккаунта? &nbsp;
          <Link to='/register' className={s.register_btn}>
            Зарегестрироваться
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default Login;
