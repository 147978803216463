import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import s from "./MyAccount.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import {
  getMessages,
  getMyApplications,
  getProfileInfo,
  logout,
} from "../../redux/ApplicationSlice";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import UserInfo from "../../components/UserInfo/UserInfo";
import ApplicationsMobileTable from "../../components/ApplicationsMobileTable/ApplicationsMobileTable";

const MyAccount = () => {
  const user = useSelector((state) => state.application.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.application.access);
  const applications = useSelector((state) => state.application.myApplications);
  const { width } = useWindowDimensions();

  const columns = [
    {
      title: "Дата",
      dataIndex: `date`,
      width: 100,
    },
    {
      title: "Компания",
      dataIndex: "company",
      width: 250,
    },
    {
      title: "Сумма",
      dataIndex: `requestedAmount`,
      width: 125,
      align: "center",
    },
    {
      title: "Цель кредита",
      dataIndex: `intendedUse`,
      width: 300,
      align: "center",
    },

    {
      title: "Cтатус",
      dataIndex: "status",
      width: 200,
    },
    {
      title: "Сообщения",
      dataIndex: `chat`,
      width: 100,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getProfileInfo());
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [dispatch, isAuthenticated]);

  return (
    <div>
      {width > 1020 ? <Header /> : <MobileHeader />}
      <div className={s.myAccount}>
        <div className={s.wrapper}>
          {width > 1020 ? (
            <div className={s.personalInfo}>
              <div className={s.personalInfo}>
                <div className={s.image}>
                  <div className={s.img_wrapper}>
                    <img src='/assets/noImage.png' alt='' className={s.img} />
                  </div>
                </div>
                <div className={s.content_item}>
                  <div className={s.content_item_title}>Email:</div>
                  <div className={s.content_item_value}>{user?.email}</div>
                </div>
                {user && user?.full_name && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>ФИО:</div>
                    <div className={s.content_item_value}>
                      {user?.full_name}
                    </div>
                  </div>
                )}
                {user?.job_title && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>Должность:</div>
                    <div className={s.content_item_value}>
                      {user?.job_title}
                    </div>
                  </div>
                )}
                {user?.company && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>Компания:</div>
                    <div className={s.content_item_value}>{user?.company}</div>
                  </div>
                )}
                {user?.address && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>Адрес:</div>
                    <div className={s.content_item_value}>{user?.address}</div>
                  </div>
                )}
                {user?.phone_number && (
                  <div className={s.content_item}>
                    <div className={s.content_item_title}>Телефон:</div>
                    <div className={s.content_item_value}>
                      {user?.phone_number}
                    </div>
                  </div>
                )}
                <Link to='/updateUser' className={s.btn}>
                  <EditOutlined className={s.edit_icon} /> Обновить профиль
                </Link>
              </div>
            </div>
          ) : (
            <div className={s.personalInfo}>
              <UserInfo />
            </div>
          )}
          <div className={s.content}>
            <h4>Мои заявления</h4>
            {width > 800 ? (
              <Table
                bordered
                pagination={false}
                columns={columns}
                className={s.tenders_table}
                dataSource={applications?.map((it) => ({
                  date: it?.created_at
                    .split(" ")[0]
                    .split("-")
                    .reverse()
                    .join("."),
                  company: it.company,
                  requestedAmount: it.requested_amount,
                  intendedUse: it.intended_use,
                  status: it.status,
                  chat: (
                    <Link
                      to={`/chat/${it.id}`}
                      className={s.link}
                      onClick={() => dispatch(getMessages(it.id))}
                    >
                      <img src='/assets/chat.png' className={s.chatIcon} />
                    </Link>
                  ),
                }))}
              />
            ) : (
              <ApplicationsMobileTable />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
