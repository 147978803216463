import React from "react";
import s from "./Tenders.module.scss";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getTenderDetails } from "../../redux/ApplicationSlice";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { Table, Tabs, Tag } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";
import MobileTenderTable from "../../components/MobileTenderTable/MobileTenderTable";
import { useTranslation } from "react-i18next";

const Tenders = () => {
  const dispatch = useDispatch();
  const tenders = useSelector((state) => state.application.tenders);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const onChange = (key) => {};

  const columns = [
    {
      title: "№",
      dataIndex: "number",
    },
    {
      title: t("title"),
      dataIndex: `tender_name`,
      width: 350,
    },
    {
      title: t("number"),
      dataIndex: `tender_number`,
      width: 125,
      align: "center",
    },
    {
      title: t("category"),
      dataIndex: `category`,
    },
    {
      title: t("published"),
      dataIndex: "published",
    },
    {
      title: t("deadline"),
      dataIndex: `deadline`,
    },
    {
      title: t("type"),
      dataIndex: `type`,
    },
    {
      title: t("status"),
      dataIndex: `status`,
    },
  ];

  return (
    <div>
      {width > 970 ? <Header /> : <MobileHeader />}

      {Array.isArray(tenders) && tenders.length > 0 ? (
        <h2 className={s.h2}>{t("tenders")}</h2>
      ) : null}

      {width > 930 ? (
        <div className={s.tenders_list}>
          <Tabs onChange={onChange} defaultActiveKey='1' type='card'>
            <TabPane tab={t("active")} key='1'>
              <Table
                bordered
                pagination={false}
                columns={columns}
                className={s.tenders_table}
                dataSource={
                  Array.isArray(tenders) &&
                  tenders
                    .filter((el) => el.status === "Актуальный")
                    .map((it, index) => ({
                      number: <div className={s.td}>{index + 1}.</div>,
                      tender_name: (
                        <Link
                          to={`/tenders/${it.id}`}
                          className={s.name}
                          onClick={() => dispatch(getTenderDetails(it.id))}
                        >
                          {it?.title}
                        </Link>
                      ),
                      tender_number: it?.tender_no,
                      category: it?.category?.name,
                      published: it?.published_date,
                      deadline: it?.deadline,
                      type: it?.announcement_type,
                      status: (
                        <div>
                          <Tag color='#7eca8f'>
                            <CheckCircleOutlined />
                            &nbsp; {t("active")}
                          </Tag>
                        </div>
                      ),
                    }))
                }
              />
            </TabPane>
            <TabPane tab={t("results")} key='2'>
              <Table
                bordered
                pagination={false}
                columns={columns}
                dataSource={
                  Array.isArray(tenders) &&
                  tenders
                    .filter((el) => el.status === "Итоги")
                    .map((it, index) => ({
                      number: <div className={s.td}>{index + 1}</div>,
                      tender_name: (
                        <Link
                          to={`/tenders/${it.id}`}
                          className={s.name}
                          onClick={() => dispatch(getTenderDetails(it.id))}
                        >
                          {it?.title}
                        </Link>
                      ),
                      tender_number: it?.tender_no,
                      category: it?.category?.name,
                      published: it?.published_date,
                      deadline: it?.deadline,
                      type: it?.announcement_type,
                      status: (
                        <div>
                          <Tag color='rgba(239, 91, 91, 0.927)'>
                            <ExclamationCircleOutlined />
                            &nbsp; {t("results")}
                          </Tag>
                        </div>
                      ),
                    }))
                }
              />
            </TabPane>
            <TabPane tab={t("completed")} key='3'>
              <Table
                bordered
                pagination={false}
                columns={columns}
                className={s.tenders_table}
                dataSource={
                  Array.isArray(tenders) &&
                  tenders
                    .filter((el) => el.status === "Завершен")
                    .map((it, index) => ({
                      number: <div className={s.td}>{index + 1}</div>,
                      tender_name: (
                        <Link
                          to={`/tenders/${it.id}`}
                          className={s.name}
                          onClick={() => dispatch(getTenderDetails(it.id))}
                        >
                          {it?.title}
                        </Link>
                      ),
                      tender_number: it?.tender_no,
                      category: it?.category?.name,
                      published: it?.published_date,
                      deadline: it?.deadline,
                      type: it?.announcement_type,
                      status: (
                        <div>
                          <Tag color='#82c9d5'>
                            <IssuesCloseOutlined />
                            &nbsp; {t("completed")}
                          </Tag>
                        </div>
                      ),
                    }))
                }
              />
            </TabPane>
          </Tabs>
        </div>
      ) : (
        <MobileTenderTable />
      )}
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Tenders;
