import React, { useEffect } from "react";
import s from "./UserApplication.module.scss";
import { Col, Drawer, Form, Input, Row, Select, message, Tooltip } from "antd";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import { useSelector } from "react-redux";
import {
  BankFilled,
  CalendarFilled,
  ContactsFilled,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Loading from "../../components/Loading/Loading";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

const props = {
  name: "file",
  action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  progress: {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    strokeWidth: 3,
    format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
  },
};

const UserApplication = ({ isOpen, setIsOpen }) => {
  const { width, height } = useWindowDimensions();
  const [form] = Form.useForm();
  const loading = useSelector((state) => state.application.loading);
  const { t } = useTranslation();
  const lang = useSelector((state) => state.application.lang);

  const application = useSelector(
    (state) => state.application.applicationDetails
  );

  useEffect(() => {
    form.setFieldsValue({
      full_name: application?.full_name,
      company: application?.company,
      phone: application?.phone,
      email: application?.email,
      project_goal: application?.project_goal,
      project_description: application?.project_description,

      industry_details: {
        value: application?.industry_details?.id,
        label: application?.industry_details?.industry,
      },
      total_cost: application?.total_cost,
      intended_use: application?.intended_use,
      existing_business_description: application?.existing_business_description,
      requested_amount: application?.requested_amount,
      pledge: application?.pledge,
      existing_loans: application?.existing_loans,
      created_at: application?.created_at,
    });
  }, [application, form]);

  return (
    <div className={"user_drawer"} style={{ position: "relative" }}>
      <Drawer
        title={t("pre-application")}
        width={width > 1000 ? "90%" : "100%"}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <Form
          form={form}
          layout='vertical'
          className={s.form}
          encType='multipart/form-data'
        >
          <div className={s.section}>
            <h3 className={s.h2}>
              <ContactsFilled className={s.icon} /> {t("aboutApplicant")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='company'
                  label={
                    <div>
                      {t("company")}{" "}
                      <Tooltip
                        placement='top'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleCompany")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterCompanyName")}
                    readonly='readonly'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='full_name'
                  label={
                    <div>
                      {t("name")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleName")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterFullName")}
                    readonly='readonly'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='phone'
                  label={
                    <div>
                      {t("contactPhone")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("examplePhone")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterPhoneNumber")}
                    readonly='readonly'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='email'
                  label={
                    <div>
                      {t("email")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              moloko@gmail.com
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterEmail")}
                    readonly='readonly'
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <CalendarFilled className={s.icon} /> {t("aboutProject")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='project_goal'
                  label={
                    <div>
                      {t("goal")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleGoal")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                >
                  <TextArea
                    className={s.form_input}
                    placeholder={t("enterProjectGoal")}
                    style={{ height: "100px" }}
                    readonly='readonly'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='project_description'
                  label={
                    <div>
                      {t("projectDescription")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleDesc")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px" }}
                    placeholder={t("enterProjectDescription")}
                    readonly='readonly'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='industry_details'
                  label={
                    <div>
                      {t("projectIndustry")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>{t("agro")}</div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <Select
                    placeholder={t("selectIndustry")}
                    className={s.form_input}
                    style={{ padding: "0", borderRadius: "10px" }}
                    disabled='disabled'
                  >
                    <Option value='агропромышленный комплекс'>
                      {t("agro")}
                    </Option>
                    <Option value='сектор водоснабжения'>{t("water")}</Option>
                    <Option value='сектор здравоохранения'>
                      {t("healthcare")}
                    </Option>
                    <Option value='автомобильная промышленность'>
                      {t("auto")}
                    </Option>
                    <Option value='наука, исследования и технология'>
                      {t("science")}
                    </Option>
                    <Option value='газопромышленный комплекс'>
                      {t("gas")}
                    </Option>
                    <Option value='химическая и нефтехимическая промышленность'>
                      {t("chemical")}
                    </Option>
                    <Option value='швейная и текстильная промышленность'>
                      {t("textile")}
                    </Option>
                    <Option value='обрабатывающая промышленность'>
                      {t("manufacturing")}
                    </Option>
                    <Option value='горнодобывающая и металлургическая промышленность'>
                      {t("mining")}
                    </Option>
                    <Option value='транспорт'>{t("transportation")}</Option>
                    <Option value='жилищное строительство'>
                      {t("residentialConstruction")}
                    </Option>
                    <Option value='торговля'>{t("trade")}</Option>
                    <Option value='развитие инфраструктуры'>
                      {t("infrastructureGrowth")}
                    </Option>
                    <Option value='информационные технологии'>{t("it")}</Option>
                    <Option value='развитие туризма'>{t("tourism")}</Option>
                    <Option value='прочие'>{t("other")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='total_cost'
                  label={
                    <div>
                      {t("totalAmount")}
                      <Tooltip
                        placement='topLeft'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>650 000 USD</div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterProjectAmountandCurrency")}
                    readOnly='readonly'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='intended_use'
                  className={s.formItem}
                  label={
                    <div>
                      {t("purpose")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        arrow={false}
                        placement='topLeft'
                        style={{
                          width: width > 600 ? "400px" : "320px",
                        }}
                        height={450}
                        width={320}
                        title={
                          <div
                            className={s.tooltip}
                            style={{
                              maxWidth: "400px",
                              width: width > 600 ? "400px" : "240px",
                            }}
                          >
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              <table className={s.table}>
                                <tr>
                                  <th style={{ width: "20px" }}>№</th>
                                  <th
                                    style={{
                                      width: width > 600 ? "250px" : "125px",
                                    }}
                                  >
                                    {t("title")}
                                  </th>
                                  <th>{t("amount")}</th>
                                </tr>
                                <tbody>
                                  <tr>
                                    <td>1. </td>
                                    <td> {t("acquisitionEquipment")} </td>
                                    <td>250 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>2.</td>
                                    <td>
                                      {t("acquisitionSpecializedEquipment")}
                                    </td>
                                    <td>70 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>3.</td>
                                    <td> {t("constructionWork")} </td>
                                    <td>330 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>4.</td>
                                    <td>…….</td>
                                    <td>…….</td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <th>{t("total")}</th>
                                    <th>650 000 USD</th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "200px" }}
                    placeholder={t("specifyLoanPurpose")}
                    readOnly='readonly'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='existing_business_description'
                  label={
                    <div>
                      {t("currentBusinessDescription")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        placement='topLeft'
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleCurrentBusiness")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                >
                  <TextArea
                    style={{
                      marginTop: lang === "ru" ? "28px" : "0",
                      height: "200px",
                    }}
                    className={s.form_input}
                    placeholder={t("describeCurrentBusiness")}
                    readOnly='readonly'
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <BankFilled className={s.icon} />
              &nbsp;
              {t("aboutLoan")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='requested_amount'
                  className={s.formItem}
                  label={
                    <div>
                      {t("requestedAmount")}
                      <Tooltip
                        placement='topLeft'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleRequestedAmount")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <TextArea
                    style={{
                      minHeight: "100px",
                      verticalAlign: "top",
                      height: "100px",
                    }}
                    className={s.form_input}
                    placeholder={t("enterRequestedAmount")}
                    readOnly='readonly'
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='existing_loans'
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("enterExistingLoans"),
                    },
                  ]}
                  label={
                    <div>
                      {t("existingLoans")}
                      <Tooltip
                        placement='topRight'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")} №1:
                            </div>
                            <div className={s.tooltipContent}>
                              {t("currentLoan")} <br />
                              <div>
                                <div
                                  className={s.tooltipTitle}
                                  style={{ marginTop: "5px" }}
                                >
                                  <ExclamationCircleOutlined
                                    className={s.tooltip_text_icon}
                                  />
                                  {t("example")} №2:
                                </div>
                                <div className={s.tooltipContent}>
                                  {t("noLoans")}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px" }}
                    placeholder={t("enterExistingLoans")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='pledge'
                  label={
                    <div>
                      {t("collateralInformation")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        placement='topLeft'
                        title={
                          <div
                            className={s.tooltip}
                            style={{
                              maxWidth: "400px",
                              width: width > 600 ? "400px" : "240px",
                            }}
                          >
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              <table className={s.table}>
                                <tbody>
                                  <tr>
                                    <td style={{ width: "20px" }}>1. </td>
                                    <td
                                      style={{
                                        width: width > 600 ? "200px" : "120px",
                                      }}
                                    >
                                      {t("nonresidential")}
                                    </td>
                                    <td>400 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>2.</td>
                                    <td>{t("residentialHouse")}</td>
                                    <td>50 000 USD</td>
                                  </tr>
                                  <tr>
                                    <td>3.</td>
                                    <td>{t("apartment")} </td>
                                    <td>70 000 USD</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("describePledge"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "200px" }}
                    placeholder={t("describePledge")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <div className={s.documents}>
                {application?.documents?.map((it) => (
                  <Link to={`${it?.file_url}`} className={s.doc_item}>
                    <img
                      src='/assets/attachment.png'
                      className={s.attachment_icon}
                    />
                    {it?.file_name}
                  </Link>
                ))}
              </div>
            </Row>
          </div>
        </Form>
        <div className={s.loader}>
          {loading ? <Loading loading={loading} /> : null}
        </div>
      </Drawer>
    </div>
  );
};

export default UserApplication;
