import React, { useEffect } from "react";
import s from "./MainPageHeader.module.scss";
import Header from "../Header/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import { useTranslation } from "react-i18next";

const MainPageHeader = () => {
  useEffect(() => {
    AOS.init({
      delay: 100,
      duration: 1000,
    });
  }, []);

  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <div className={s.header}>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.content}>
        <div className={s.text}>
          <div className={s.title}>
            {" "}
            <div
              data-aos='fade-down'
              data-aos-anchor-placement='bottom-bottom'
              data-aos-duration='1500'
              style={{ letterSpacing: "4px" }}
            >
              {t("az-kg")}
            </div>{" "}
            <br />
            <div
              data-aos='fade-down'
              data-aos-anchor-placement='bottom-bottom'
              data-aos-duration='2000'
              style={{ letterSpacing: "4px" }}
            >
              {t("developmentFund")}
            </div>
          </div>
          <div
            className={s.subtitle}
            data-aos='fade-up'
            data-aos-duration='2000'
          >
            {t("slogan")}
          </div>
        </div>
        <div className={s.logo}>
          <img
            src='/3D_logo.png'
            className={s.logoImg}
            data-aos='fade-up'
            data-aos-anchor-placement='bottom-bottom'
          />
        </div>
      </div>
    </div>
  );
};

export default MainPageHeader;
