import React, { useState } from "react";
import s from "./CreditCaltulator.module.scss";
import { useTranslation } from "react-i18next";
import { Loan } from "loanjs";
import { NumericFormat } from "react-number-format";
import { Button, Drawer, Form, Select, Space, Table, Typography } from "antd";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
const { Text } = Typography;

const CreditCalculator = () => {
  const { t } = useTranslation();
  const [loanAmount, setLoanAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [loan, setLoan] = useState();
  const [currency, setCurrency] = useState("KGS");
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();

  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: t("month"),
      dataIndex: "month",
      width: 20,
      align: "center",
    },
    {
      title: t("principalAmount"),
      dataIndex: `capital`,
      width: 600,
    },
    {
      title: `${t("accruedInterest")}, %`,
      dataIndex: `interests`,
      width: 500,
    },
    {
      title: t("payment"),
      dataIndex: `installment`,
      width: 500,
    },
    {
      title: t("remainingBalanceAfterRepayment"),
      dataIndex: `restOfCapital`,
      width: 600,
    },
  ];

  const handleCalculate = async (values) => {
    const loan = await new Loan(
      values.loanAmount,
      values.loanTerm,
      values.interestRate,
      "annuity"
    );
    setLoan(loan);
    setLoanAmount(values.loanAmount);
    setInterestRate(values.interestRate);
    setLoanTerm(values.loanTerm);
  };
  return (
    <div className={s.calculator}>
      <h2 className={s.h2}>{t("calculateLoan")}</h2>
      <div className={s.content}>
        <Form
          className={s.form}
          layout='vertical'
          form={form}
          onFinish={handleCalculate}
        >
          <Form.Item
            label={t("loanAmount")}
            name='loanAmount'
            rules={[
              {
                required: true,
                message: t("specifyLoanAmount"),
              },
            ]}
          >
            <input
              className={s.input}
              type='number'
              placeholder={t("specifyLoanAmount")}
              value={loanAmount}
            />
          </Form.Item>
          <Form.Item label={t("currency")} name='currency'>
            <Select
              defaultValue='KGS'
              className={s.calculatorSelect}
              style={{
                borderRadius: "0",
                background: "white",
                padding: "22.5px 5px",
                outline: "none",
              }}
              onChange={(value) => setCurrency(value)}
              options={[
                {
                  value: "KGS",
                  label: "KGS",
                },
                {
                  value: "USD",
                  label: "USD",
                },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            label={`${t("interestRate")}, %`}
            name='interestRate'
            rules={[
              {
                required: true,
                message: t("specifyInterestRate"),
              },
            ]}
          >
            <input
              className={s.input}
              type='number'
              placeholder={`${t("specifyInterestRate")}, %`}
              value={interestRate}
            />
          </Form.Item>
          <Form.Item
            label={t("loanTerm")}
            name='loanTerm'
            rules={[
              {
                required: true,
                message: t("specifyLoanTerm"),
              },
            ]}
          >
            <input
              className={s.input}
              type='number'
              placeholder={t("specifyLoanTerm")}
              value={loanTerm}
            />
          </Form.Item>
          <Form.Item>
            <button className={s.btn} htmlType='submit'>
              {t("calculate")}
            </button>
          </Form.Item>
        </Form>
        <div className={s.credit_results}>
          <div>
            <div className={s.result_title}>{t("payment")}</div>
            <div className={s.result_value}>
              {loan?.installments ? (
                <NumericFormat
                  value={
                    Array.isArray(loan?.installments) &&
                    loan?.installments[0]?.installment
                  }
                  decimalSeparator='.'
                  thousandSeparator=' '
                  displayType={"text"}
                />
              ) : (
                "--"
              )}
              &nbsp;{loan && currency}
            </div>
          </div>
          <div>
            <div className={s.result_title}>{t("totalAmountforPeriod")}</div>
            <div className={s.result_value}>
              {loan?.sum ? (
                <NumericFormat
                  value={loan?.sum}
                  decimalSeparator='.'
                  thousandSeparator=' '
                  displayType={"text"}
                />
              ) : (
                "--"
              )}
              &nbsp;{loan && currency}
            </div>
          </div>
          <div>
            <div className={s.result_title}>{t("totalInterest")}</div>
            <div className={s.result_value}>
              {loan?.interestSum ? (
                <NumericFormat
                  value={loan?.interestSum}
                  decimalSeparator='.'
                  thousandSeparator=' '
                  displayType={"text"}
                />
              ) : (
                "--"
              )}
              &nbsp;{loan && currency}
            </div>
          </div>
          {loan && (
            <button className={s.schedule_btn} onClick={() => setOpen(true)}>
              {t("sampleRepaymentSchedule")}
            </button>
          )}
        </div>
      </div>
      <Drawer
        title={t("sampleRepaymentSchedule").toUpperCase()}
        placement={"right"}
        width={width > 700 ? "85%" : "100%"}
        onClose={onClose}
        open={open}
        className={s.drawer}
        extra={
          <Space>
            {width > 700 ? (
              <Button onClick={onClose} className={s.btn}>
                Cancel
              </Button>
            ) : null}
          </Space>
        }
      >
        <div className={s.table}>
          <div className={s.table_header}>
            <div className={s.table_header_item}>
              <div>{t("loanAmount")}:</div>&nbsp;
              <div className={s.table_header_value}>
                {loanAmount.toLocaleString()}&nbsp;{currency}
              </div>
            </div>
            <div className={s.table_header_item}>
              <div>{t("interestRate")}:</div>&nbsp;
              <div className={s.table_header_value}>{interestRate}%</div>
            </div>
            <div className={s.table_header_item}>
              <div>{t("loanTerm")}:</div>&nbsp;
              <div className={s.table_header_value}>{loanTerm}&nbsp;мес.</div>
            </div>
          </div>
          <div>
            <Table
              bordered
              pagination={false}
              columns={columns}
              dataSource={
                Array.isArray(loan?.installments) &&
                loan.installments?.map((it, index) => ({
                  month: `${index + 1}`,
                  capital: (
                    <NumericFormat
                      value={it?.capital?.toFixed(2)}
                      decimalSeparator='.'
                      thousandSeparator=' '
                      displayType={"text"}
                    />
                  ),
                  interests: (
                    <NumericFormat
                      value={it?.interest?.toFixed(2)}
                      decimalSeparator='.'
                      thousandSeparator=' '
                      displayType={"text"}
                    />
                  ),
                  installment: (
                    <NumericFormat
                      value={it?.installment?.toFixed(2)}
                      decimalSeparator='.'
                      thousandSeparator=' '
                      displayType={"text"}
                    />
                  ),
                  restOfCapital: (
                    <NumericFormat
                      value={(it?.remain).toFixed(2)}
                      decimalSeparator='.'
                      thousandSeparator=' '
                      displayType={"text"}
                    />
                  ),
                }))
              }
              className='appriser_table'
              summary={(pageData) => (
                <Table.Summary.Row className={s.totalRow}>
                  <Table.Summary.Cell index={0}>
                    <Text strong className={s.total}>
                      {t("total")}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text strong>
                      <NumericFormat
                        className={s.total}
                        value={loan?.amount.toFixed(2)}
                        decimalSeparator='.'
                        thousandSeparator=' '
                        displayType={"text"}
                      />
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Text strong>
                      <NumericFormat
                        className={s.total}
                        value={loan?.interestSum?.toFixed(2)}
                        decimalSeparator='.'
                        thousandSeparator=' '
                        displayType={"text"}
                      />
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text strong>
                      <NumericFormat
                        className={s.total}
                        value={loan?.sum?.toFixed(2)}
                        decimalSeparator='.'
                        thousandSeparator=' '
                        displayType={"text"}
                      />
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text strong className={s.total}></Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreditCalculator;
