import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { publicRequest, userRequest } from "../utils";

const initialState = {
  application: {},
  news: [],
  newsDetails: {},
  slider: [],
  tenders: [],
  tenderDetails: {},
  vacancies: [],
  vacancyDetails: {},
  feedback: {},
  boardMembers: [],
  councilMembers: [],
  reports: [],
  contacts: {},
  loading: false,
  consultants: [],
  appraisers: [],
  insuranceCompanies: [],
  lang: "ru",
  email: null,
  access: null,
  refresh: null,
  user: {},
  myApplications: [],
  messages: [],
  usersList: [],
  applicationsList: [],
  applicationDetails: {},
  messageReceiver: {},
  appStatusAndComment: {},
  confirmEmailMessage: "",
};

export const sendApplication = createAsyncThunk(
  "application/sendApplication",
  async (info, { getState }) => {
    try {
      const access = getState().application.access;
      const { data } = await publicRequest.post(
        `main/api/v1/applications/`,
        info,
        access && {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const sendFeedback = createAsyncThunk(
  "application/sendFeedback",
  async (info) => {
    try {
      const { data } = await publicRequest.post(
        `content/api/v1/feedback/`,
        info
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getNews = createAsyncThunk("main/getNews", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    let lang = state.application.lang;

    const { data } = await publicRequest.get(`content/api/v1/news/`, {
      headers: {
        "Accept-Language": lang,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getNewsDetails = createAsyncThunk(
  "main/getNewsDetails",
  async (id, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(`content/api/v1/news/${id}/`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSlider = createAsyncThunk("main/getSlider", async (id) => {
  try {
    const { data } = await publicRequest.get(`main/api/v1/main-slider/`);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getTenders = createAsyncThunk(
  "main/tenders",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(`tender/api/v1/tenders/`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTenderDetails = createAsyncThunk(
  "main/tender",
  async (id, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(`tender/api/v1/tenders/${id}/`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getVacancies = createAsyncThunk(
  "main/vacancies",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(`content/api/v1/vacancy/`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getVacancyDetails = createAsyncThunk(
  "main/vacancyDetails",
  async (id, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(
        `content/api/v1/vacancy/${id}/`,
        {
          headers: {
            "Accept-Language": lang,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await publicRequest.post(
        `user/api/v1/registration/`,
        params
      );
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(
        error.response.data.detail ||
          error.response.data.non_field_errors[0] ||
          error.message
      );
    }
  }
);

export const confirmEmail = createAsyncThunk(
  "user/confirmEmail",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await publicRequest.get(
        `/user/api/v1/account-confirm-email/${id}/`
      );
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error?.response?.data?.detail || error.message);
    }
  }
);

export const login = createAsyncThunk(
  "user/login",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await publicRequest.post(
        `user/api/v1/login/access/`,
        params
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.detail || error.message);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "user/refreshToken",
  async (_, { getState }) => {
    try {
      const refresh = getState().application.refresh;
      const { data } = await publicRequest.post(`user/api/v1/login/refresh/`, {
        refresh,
      });

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProfileInfo = createAsyncThunk(
  "user/getProfileInfo",
  async (_, { getState }) => {
    try {
      const access = getState().application.access;
      console.log("acces", access);
      const { data } = await userRequest.get(`user/api/v1/profile/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUsersList = createAsyncThunk(
  "admin/getUsersList",
  async (_, { getState }) => {
    try {
      const access = getState().application.access;

      const { data } = await userRequest.get(`manager/api/v1/active-users/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getApplicationsList = createAsyncThunk(
  "admin/getApplicationsList",
  async (_, { getState }) => {
    try {
      const access = getState().application.access;

      const { data } = await userRequest.get(
        `manager/api/v1/all-applications/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getApplicationDetails = createAsyncThunk(
  "admin/getApplicationDetails",
  async (id, { getState }) => {
    try {
      const access = getState().application.access;

      const { data } = await userRequest.get(
        `manager/api/v1/all-applications/${id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getMyApplications = createAsyncThunk(
  "user/getMyAppliocations",
  async (_, { getState }) => {
    try {
      const access = getState().application.access;

      const { data } = await userRequest.get(`user/api/v1/myapplications/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getMessages = createAsyncThunk(
  "user/getMessages",
  async (id, { getState }) => {
    try {
      const access = getState().application.access;
      const { data } = await userRequest.get(
        `http://138.68.94.26:8000/main/api/v1/application/${id}/chat/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const postMessage = createAsyncThunk(
  "user/postMessage",
  async ({ id, params }, { getState }) => {
    try {
      const access = getState().application.access;
      const { data } = await userRequest.post(
        `main/api/v1/application/${id}/chat/`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateStatusAndComment = createAsyncThunk(
  "admin/patchStatusAndComment",
  async (params, { getState }) => {
    console.log("params", params);
    try {
      const access = getState().application.access;
      const { data } = await userRequest.patch(
        `manager/api/v1/update-app-status/${params.id}/`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (params, { getState }) => {
    try {
      const access = getState().application.access;
      const { data } = await userRequest.patch(`user/api/v1/update/`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const sendEmailToResetPassword = createAsyncThunk(
  "user/sendEmailToResetPassword",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await publicRequest.post(
        `user/api/v1/password/reset/`,
        params
      );
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || error.response.data.error
      );
    }
  }
);

export const setNewPassword = createAsyncThunk(
  "user/setNewPassword",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await publicRequest.post(
        `user/api/v1/password/reset/confirm/new/pass/`,
        params
      );
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || error.response.data.error
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await publicRequest.post(
        `user/api/v1/password/change/`,
        params
      );
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || error.response.data.error
      );
    }
  }
);

export const getBoardMembers = createAsyncThunk(
  "main/boardMembers",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(
        `content/api/v1/board-of-directors/`,
        {
          headers: {
            "Accept-Language": lang,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCouncilMembers = createAsyncThunk(
  "main/councilMembers",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;

      const { data } = await publicRequest.get(
        `content/api/v1/fund-council-members/`,
        {
          headers: {
            "Accept-Language": lang,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getReports = createAsyncThunk(
  "main/reports",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(
        `content/api/v1/normative-documents/`,
        {
          headers: {
            "Accept-Language": lang,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getContacts = createAsyncThunk(
  "main/contacts",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(`content/api/v1/contacts/1/`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getConsultants = createAsyncThunk(
  "main/consultants",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(`content/api/v1/consultants/`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getApprisers = createAsyncThunk(
  "main/apprisers",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(`content/api/v1/appraisers/`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getInsuranceCompanies = createAsyncThunk(
  "main/insuranceCompanies",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const lang = state.application.lang;
      const { data } = await publicRequest.get(
        `content/api/v1/insurance-company/`,
        {
          headers: {
            "Accept-Language": lang,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setLang(state, action) {
      state.lang = action.payload;
    },
    logout(state, action) {
      state.access = null;
      state.refresh = null;
      state.email = null;
      localStorage.clear();
    },
    setMessageReceiver(state, action) {
      state.messageReceiver = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.news = action.payload;
        state.loading = false;
      })
      .addCase(getNews.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getNewsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewsDetails.fulfilled, (state, action) => {
        state.newsDetails = action.payload;
        state.loading = false;
      })
      .addCase(getNewsDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSlider.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSlider.fulfilled, (state, action) => {
        state.slider = action.payload;
        state.loading = false;
      })
      .addCase(getSlider.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTenders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenders.fulfilled, (state, action) => {
        state.tenders = action.payload;
        state.loading = false;
      })
      .addCase(getTenders.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTenderDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenderDetails.fulfilled, (state, action) => {
        state.tenderDetails = action.payload;
        state.loading = false;
      })
      .addCase(getTenderDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getVacancies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVacancies.fulfilled, (state, action) => {
        state.vacancies = action.payload;
        state.loading = false;
      })
      .addCase(getVacancies.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getVacancyDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVacancyDetails.fulfilled, (state, action) => {
        state.vacancyDetails = action.payload;
        state.loading = false;
      })
      .addCase(getVacancyDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.reports = action.payload;
        state.loading = false;
      })
      .addCase(getReports.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getBoardMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBoardMembers.fulfilled, (state, action) => {
        state.boardMembers = action.payload;
        state.loading = false;
      })
      .addCase(getBoardMembers.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getCouncilMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCouncilMembers.fulfilled, (state, action) => {
        state.councilMembers = action.payload;
        state.loading = false;
      })
      .addCase(getCouncilMembers.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.contacts = action.payload;
        state.loading = false;
      })
      .addCase(getContacts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(sendApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendApplication.fulfilled, (state, action) => {
        state.application = action.payload;
        state.loading = false;
      })
      .addCase(sendApplication.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getConsultants.fulfilled, (state, action) => {
        state.consultants = action.payload;
      })

      .addCase(getApprisers.fulfilled, (state, action) => {
        state.appraisers = action.payload;
      })

      .addCase(getInsuranceCompanies.fulfilled, (state, action) => {
        state.insuranceCompanies = action.payload;
      })

      .addCase(register.fulfilled, (state, action) => {
        state.email = action.payload?.email;
        state.message = action.payload?.message;
        state.remainingTime = action.payload?.remaining_time;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.access = action.payload.access;
        state.refresh = action.payload.refresh;
        state.email = action.payload.email;
        localStorage.clear();
        localStorage.setItem("access_token", action.payload.access);
        localStorage.setItem("refresh_token", action.payload.refresh);
      })
      .addCase(getProfileInfo.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })

      .addCase(getMyApplications.fulfilled, (state, action) => {
        state.myApplications = action.payload;
      })

      .addCase(getUsersList.fulfilled, (state, action) => {
        state.usersList = action.payload;
      })
      .addCase(getApplicationsList.fulfilled, (state, action) => {
        state.applicationsList = action.payload;
      })
      .addCase(getApplicationDetails.fulfilled, (state, action) => {
        state.applicationDetails = action.payload;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.messages = action.payload;
      })

      .addCase(confirmEmail.fulfilled, (state, action) => {
        state.confirmEmailMessage = action.payload;
      })
      .addCase(updateStatusAndComment.fulfilled, (state, action) => {
        state.appStatusAndComment = action.payload;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.access = action.payload.access;
        state.refresh = action.payload.refresh;
      });
  },
});
export const { setLang, logout, setMessageReceiver } = applicationSlice.actions;
export default applicationSlice.reducer;
