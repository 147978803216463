import React from "react";
import Header from "../../components/Header/Header";
import s from "./Team.module.scss";
import TeamItem from "../../components/TeamItem/TeamItem";
import Footer from "../../components/Footer/Footer";
import ManagementItem from "../../components/ManagmentItem/ManagementItem";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useSelector } from "react-redux";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { height, width } = useWindowDimensions();
  const boardMembers = useSelector((state) => state.application.boardMembers);
  const { t } = useTranslation();
  const councilMembers = useSelector(
    (state) => state.application.councilMembers
  );

  return (
    <div className={s.team}>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.line}></div>
      <div className={s.team_content}>
        <div className={s.union}>
          <h2 className={s.h2}>{t("council")}</h2>
          <div className={s.subtitle}>{t("councilIntro")}</div>
          <div className={s.management}>
            {Array.isArray(boardMembers) &&
              boardMembers.map((it) => <ManagementItem data={it} />)}
          </div>
        </div>
        <h2 className={s.h2}>{t("board")}</h2>
        <div className={s.subtitle}>{t("boardIntro")}</div>
        <div className={s.team_members}>
          {Array.isArray(councilMembers) &&
            councilMembers.map(
              (it) =>
                (it.role === "Сопредседатель" || it.role === "Co-Chair") && (
                  <TeamItem data={it} />
                )
            )}
        </div>
        <div className={s.team_members}>
          {Array.isArray(councilMembers) &&
            councilMembers.map(
              (it) =>
                (it.role === "Член Правления" ||
                  it.role === "Board Member") && <TeamItem data={it} />
            )}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Team;
